import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { loadTicketsU } from "../../redux/tasks/ticketsT";
import TopBar from "../../components/TopBar";
import Sidebar from "../../components/Sidebar";
import ParamGraph from "../../components/ParamGraph";
import Spinner from "../../components/Spinner";

const DashboardUser = ({ loading, tickets, user, loadTicketsU }) => {
   useEffect(() => {
      loadTicketsU();
   }, [loadTicketsU]);
   return loading ? (
      <Spinner />
   ) : (
      <>
         <div className="flex flex-col h-screen">
            <TopBar />
            <div className="flex flex-1 overflow-hidden">
               <Sidebar />
               <main className=" flex flex-col flex-1 p-4 overflow-y-auto justify-center items-center">
                  <div className="flex  flex-col w-full justify-center  items-center">
                     <h1 className="text-3xl font-bold">Dashboard</h1>
                     <h1 className="text-xl font-semibold text-neutral-600">
                        Graphs below maps your created tickets on the date they
                        were created
                     </h1>
                  </div>
                  {tickets?.length > 0 ? (
                     <ParamGraph tickets={tickets} role={user.role} />
                  ) : (
                     <h1>No Tickets available</h1>
                  )}
               </main>
            </div>
         </div>
      </>
   );
};

DashboardUser.propTypes = {
   loading: PropTypes.bool.isRequired,
   tickets: PropTypes.array,
   user: PropTypes.object,
   loadTicketsU: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
   loading: state.tickets.loading,
   tickets: state.tickets.tickets,
   user: state.auth.user,
});

export default connect(mapStateToProps, { loadTicketsU })(DashboardUser);
