import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { format } from "date-fns";

import { loadTickets, deleteMultipleTickets } from "../../redux/tasks/ticketsT";
import TopBar from "../../components/TopBar";
import Sidebar from "../../components/Sidebar";
import Spinner from "../../components/Spinner";
import Table from "../../components/Table";

const Tickets = ({ loading, tickets, loadTickets, deleteMultipleTickets }) => {
   const navigate = useNavigate();
   const [load, setLoad] = useState(false);
   const [ticketIds, setTicketIds] = useState([]);
   const [numberOptions] = useState([5, 10, 15, 20, 25, 30]);
   const [globalFilter, setGlobalFilter] = useState("");

   useEffect(() => {
      loadTickets();
   }, [loadTickets, loading]);

   const handleDelete = (index) => {
      setLoad(true);
      deleteMultipleTickets(toast, setLoad, ticketIds, navigate);
   };

   const handleData = (index) => {
      const ticket = tickets[index];
      navigate("/single-ticket", { state: { ticket } });
   };

   const handleCheckboxChange = (e, ticket) => {
      e.stopPropagation();
      if (ticketIds.includes(ticket)) {
         setTicketIds(ticketIds.filter((item) => item !== ticket));
      } else {
         setTicketIds([...ticketIds, ticket]);
      }
   };

   const TableColumns = [
      {
         Header: ".",
         accessor: "checkbox",
         Cell: ({ row }) => (
            <input
               type="checkbox"
               checked={ticketIds.includes(row.original._id)}
               onChange={(e) => handleCheckboxChange(e, row.original._id)}
               onClick={(e) => e.stopPropagation()}
            />
         ),
      },
      {
         Header: "#",
         accessor: "ticketNo",
      },
      {
         Header: "Subject",
         accessor: "subject",
      },
      {
         Header: "Department",
         accessor: "department",
      },
      {
         Header: "Status",
         accessor: "status",
      },
      {
         Header: "Created at",
         accessor: "createdAt",
         Cell: ({ value }) => {
            return format(new Date(value), "MM-dd-yyyy");
         },
      },
   ];

   return loading || load ? (
      <Spinner />
   ) : (
      <>
         <div className="flex flex-col h-screen">
            <TopBar />
            <div className="flex flex-1 overflow-hidden">
               <Sidebar />
               <main className="flex-1 p-4 overflow-y-auto">
                  <div className=" mx-auto">
                     <div className="flex flex-col gap-x-6 sm:flex-row sm:items-center sm:justify-end space-y-2 sm:space-y-0 sm:space-x-2 p-4">
                        <button
                           onClick={(e) => handleDelete(e)}
                           className={`font-semibold py-2 px-9 rounded sm:order-2 ${
                              ticketIds.length > 0
                                 ? "bg-sysRed hover:bg-red-600 text-white"
                                 : "bg-neutral-300 text-neutral-700"
                           }`}
                        >
                           Delete
                        </button>
                        <div className="flex items-center border border-gray-300 rounded sm:order-1">
                           <input
                              type="text"
                              placeholder="Search"
                              className="p-2 w-full border-none outline-none"
                              value={globalFilter}
                              onChange={(e) => setGlobalFilter(e.target.value)}
                           />
                           <div className="p-2">
                              <FaSearch className="text-gray-500" />
                           </div>
                        </div>
                     </div>
                     {tickets?.length > 0 ? (
                        <Table
                           data={tickets}
                           columns={TableColumns}
                           numberOptions={numberOptions}
                           dataFun={handleData}
                           globalFilter={globalFilter}
                           setGlobalFilter={setGlobalFilter}
                        />
                     ) : (
                        <div className="flex justify-center mt-10  text-2xl font-semibold">
                           {" "}
                           No Tickets Found!
                        </div>
                     )}
                  </div>
               </main>
            </div>
         </div>
      </>
   );
};

Tickets.propTypes = {
   loading: PropTypes.bool.isRequired,
   tickets: PropTypes.array,
   loadTickets: PropTypes.func.isRequired,
   deleteMultipleTickets: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
   loading: state.tickets.loading,
   tickets: state.tickets.tickets,
});

export default connect(mapStateToProps, { loadTickets, deleteMultipleTickets })(
   Tickets
);
