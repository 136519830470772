import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { addHotel } from "../../redux/tasks/hotelsT";
import { loadRegions } from "../../redux/tasks/regionsT";
import TopBar from "../../components/TopBar";
import Sidebar from "../../components/Sidebar";
import { toast } from "react-toastify";
import Spinner from "../../components/Spinner";

const HotelAddNew = ({ loading, regions, addHotel, loadRegions }) => {
   const naviagte = useNavigate();
   const [load, setLoad] = useState(false);
   const [formData, setFormData] = useState({
      name: "",
      address: "",
      phone: "",
      innCode: "",
      regionId: "",
   });

   useEffect(() => {
      loadRegions();
   }, [loadRegions]);

   const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
   };

   const handleSubmit = (e) => {
      e.preventDefault();
      setLoad(true);
      // Handle form submission logic
      console.log(formData);
      addHotel(toast, setLoad, formData, naviagte);
   };

   return load || loading ? (
      <Spinner />
   ) : (
      <div className="flex flex-col h-screen">
         <TopBar />
         <div className="flex flex-1 overflow-hidden">
            <Sidebar />
            <main className="flex-1 p-4 overflow-y-auto">
               <div className="flex flex-col h-full items-center">
                  <h1 className="text-3xl font-bold my-10 text-center">
                     Add New Hotel
                  </h1>
                  <form
                     onSubmit={handleSubmit}
                     className="w-full max-w-2xl space-y-6"
                  >
                     <div className="flex flex-col md:flex-row md:space-x-6">
                        <div className="flex-1 mb-4 md:mb-0">
                           <label className="block font-semibold mb-1">
                              Name<span className="text-red-500">*</span>
                           </label>
                           <input
                              type="text"
                              name="name"
                              placeholder="Enter Your hotel...."
                              value={formData.name}
                              onChange={handleChange}
                              className="w-full p-2 border rounded"
                              required
                           />
                        </div>
                        <div className="flex-1 mb-4 md:mb-0">
                           <label className="flex-1 mb-1 font-semibold">
                              Region<span className="text-red-500">*</span>
                           </label>
                           <select
                              name="regionId"
                              value={formData.regionId}
                              onChange={handleChange}
                              className="w-full p-2 border rounded"
                              required
                           >
                              <option value="">Select Here</option>
                              {regions?.map((d) => (
                                 <option key={d._id} value={d._id}>
                                    {d.name}
                                 </option>
                              ))}
                           </select>
                        </div>
                     </div>
                     <div className="flex flex-col md:flex-row md:space-x-6">
                        <div className="flex-1 mb-4 md:mb-0">
                           <label className="block font-semibold mb-1">
                              Phone Number
                              <span className="text-red-500">*</span>
                           </label>
                           <input
                              type="text"
                              name="phone"
                              placeholder="Enter Phone Number here"
                              value={formData.phone}
                              onChange={handleChange}
                              className="w-full p-2 border rounded"
                              required
                           />
                        </div>
                        <div className="flex-1">
                           <label className="block font-semibold mb-1">
                              INN Code<span className="text-red-500">*</span>
                           </label>
                           <input
                              type="text"
                              name="innCode"
                              placeholder="Enter code here...."
                              value={formData.iinCode}
                              onChange={handleChange}
                              className="w-full p-2 border rounded"
                              required
                           />
                        </div>
                     </div>
                     <div className="flex-1">
                        <label className="block font-semibold mb-1">
                           Address<span className="text-red-500">*</span>
                        </label>
                        <input
                           type="text"
                           name="address"
                           placeholder="Enter your Address....."
                           value={formData.address}
                           onChange={handleChange}
                           className="w-full p-2 border rounded"
                           required
                        />
                     </div>
                     <div className="flex justify-center mt-6 space-x-5">
                        <button
                           type="submit"
                           className="bg-primary hover:bg-neutral-500 font-semibold text-white py-2 px-8 rounded"
                        >
                           Submit
                        </button>
                        <button
                           onClick={(e) => naviagte(-1)}
                           className="bg-sysRed hover:bg-neutral-500 font-semibold text-white py-2 px-8 rounded"
                        >
                           Cancel
                        </button>
                     </div>
                  </form>
               </div>
            </main>
         </div>
      </div>
   );
};

HotelAddNew.propTypes = {
   loading: PropTypes.bool.isRequired,
   regions: PropTypes.array,
   addHotel: PropTypes.func.isRequired,
   loadRegions: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
   loading: state.regions.loading,
   regions: state.regions.regions,
});

export default connect(mapStateToProps, { addHotel, loadRegions })(HotelAddNew);
