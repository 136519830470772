import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { loadTickets } from "../../redux/tasks/ticketsT";
import TopBar from "../../components/TopBar";
import Sidebar from "../../components/Sidebar";
import Spinner from "../../components/Spinner";
import ParamGraph from "../../components/ParamGraph";

const DashboardAdmin = ({ loading, tickets, loadTickets, role }) => {
   useEffect(() => {
      loadTickets();
   }, [loadTickets]);

   return loading ? (
      <Spinner />
   ) : (
      <>
         <div className="flex flex-col h-screen">
            <TopBar />
            <div className="flex flex-1 overflow-hidden">
               <Sidebar />
               <main className="flex-1 p-4 overflow-y-auto">
                  {tickets?.length > 0 ? (
                     <ParamGraph tickets={tickets} role={role} />
                  ) : (
                     <h1 className="flex justify-center items-center font-bold text-3xl mt-20">
                        No Tickets available
                     </h1>
                  )}
               </main>
            </div>
         </div>
      </>
   );
};

DashboardAdmin.propTypes = {
   loading: PropTypes.bool.isRequired,
   tickets: PropTypes.array,
   role: PropTypes.string,
   loadTickets: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
   loading: state.tickets.loading,
   tickets: state.tickets.tickets,
   role: state.auth.user.role,
});

export default connect(mapStateToProps, { loadTickets })(DashboardAdmin);
