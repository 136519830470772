import { combineReducers } from "redux";

import auth from "./auth";
import users from "./users";
import hotels from "./hotels";
import regions from "./regions";
import departments from "./departments";
import tickets from "./tickets";

export default combineReducers({
   auth,
   users,
   hotels,
   regions,
   tickets,
   departments,
});
