import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { addDepartment } from "../../redux/tasks/departmentsT";
import TopBar from "../../components/TopBar";
import Sidebar from "../../components/Sidebar";
import { toast } from "react-toastify";
import Spinner from "../../components/Spinner";

const DepartmentAddNew = ({ addDepartment }) => {
   const navigate = useNavigate();
   const [load, setLoad] = useState(false);
   const [departmentName, setDepartmentName] = useState("");
   const [category, setCategory] = useState("");
   const [categories, setCategories] = useState([]);

   const handleAddCategory = () => {
      if (category) {
         setCategories([...categories, category]);
         setCategory("");
      }
   };

   const handleRemoveCat = (index) => {
      setCategories(categories.filter((_, i) => i !== index));
   };

   const handleSubmit = (e) => {
      e.preventDefault();
      setLoad(true);
      addDepartment(toast, setLoad, departmentName, categories, navigate);
   };

   return load ? (
      <Spinner />
   ) : (
      <>
         <div className="flex flex-col h-screen">
            <TopBar />
            <div className="flex flex-1 overflow-hidden">
               <Sidebar />
               <main className="flex-1 p-4 overflow-y-auto">
                  <div className="flex flex-col items-center p-4">
                     <h2 className="text-3xl font-bold text-center mb-6">
                        New Department
                     </h2>
                     <form
                        onSubmit={handleSubmit}
                        className="w-full max-w-md space-y-4"
                     >
                        <div>
                           <label className="block mb-1">
                              Department Name
                              <span className="text-red-500">*</span>
                           </label>
                           <input
                              type="text"
                              placeholder="Enter Your Title..."
                              value={departmentName}
                              onChange={(e) =>
                                 setDepartmentName(e.target.value)
                              }
                              className="w-full p-2 border rounded"
                              required
                           />
                        </div>
                        <div>
                           <label className="block mb-1">
                              Category<span className="text-red-500">*</span>
                           </label>
                           <div className="flex items-center gap-x-6">
                              <input
                                 type="text"
                                 placeholder="Enter Text Here..."
                                 value={category}
                                 onChange={(e) => setCategory(e.target.value)}
                                 className="flex-grow p-2 border rounded-l"
                              />
                              <button
                                 type="button"
                                 onClick={handleAddCategory}
                                 className="py-1 px-3 text-2xl font-semibold bg-primary hover:bg-secondry text-white rounded"
                              >
                                 +
                              </button>
                           </div>
                        </div>
                        <div>
                           {categories.length > 0 && (
                              <div className="mb-4">
                                 <h3 className="font-semibold mb-2">
                                    Categories:
                                 </h3>
                                 <ul className="list-disc list-inside">
                                    {categories.map((cat, index) => (
                                       <li
                                          key={index}
                                          className="flex w-full items-center justify-around text-xl font-semibold  my-2"
                                       >
                                          {cat}
                                          <button
                                             type="button"
                                             onClick={() =>
                                                handleRemoveCat(index)
                                             }
                                             className="px-3  py-1 text-2xl font-semibold bg-sysRed hover:bg-red-600 text-white rounded"
                                          >
                                             -
                                          </button>
                                       </li>
                                    ))}
                                 </ul>
                              </div>
                           )}
                        </div>
                        <button
                           type="submit"
                           className="w-full py-2 bg-primary hover:bg-secondry text-white font-bold rounded"
                        >
                           Add Department
                        </button>
                        <button
                           type="submit"
                           onClick={(e) => navigate(-1)}
                           className="w-full py-2 bg-sysRed hover:bg-red-700 text-white font-bold rounded"
                        >
                           Cancel
                        </button>
                     </form>
                  </div>
               </main>
            </div>
         </div>
      </>
   );
};

DepartmentAddNew.propTypes = {
   addDepartment: PropTypes.func.isRequired,
};

export default connect(null, { addDepartment })(DepartmentAddNew);
