import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";

const ProtectedRoutes = ({ children, loading, isAuth }) =>
   !isAuth && loading ? <Navigate to="/" /> : children;

ProtectedRoutes.propTypes = {
   isAuth: PropTypes.bool,
   loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
   isAuth: state.auth.isAuth,
   loading: state.auth.loading,
});

export default connect(mapStateToProps)(ProtectedRoutes);
