import {
   DEPARTMENTS,
   DEPARTMENTS_SINGLE,
   DEPARTMENTS_ERROR,
   LOGOUT,
} from "../tasks/types";

const initialState = {
   loading: true,
   departments: null,
   singleDepartment: null,
   singleDepartmentLoading: true,
   error: null,
};

export default function authR(state = initialState, action) {
   const { type, payload } = action;
   switch (type) {
      case DEPARTMENTS:
         return {
            ...state,
            loading: false,
            departments: payload.departments,
         };
      case DEPARTMENTS_SINGLE:
         return {
            ...state,
            singleDepartmentLoading: false,
            singleDepartment: payload.department,
         };
      case DEPARTMENTS_ERROR:
         return {
            ...state,
            loading: false,
            error: payload,
         };
      case LOGOUT:
         return initialState;
      default:
         return state;
   }
}
