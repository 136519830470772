import { BrowserRouter, Route, Routes } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";

import routes from "./utils/routes";
import setAuthToken from "./utils/setAuthToken";
import { store, persistor } from "./redux/store";

function App() {
   if (localStorage.token) {
      setAuthToken(localStorage.token);
   }
   return (
      <Provider store={store}>
         <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
               <Routes>
                  {routes.map((route) => (
                     <Route
                        key={route.path}
                        path={route.path}
                        element={route.component}
                     />
                  ))}
               </Routes>
            </BrowserRouter>
         </PersistGate>
      </Provider>
   );
}

export default App;
