import axios from "axios";

import { URL } from "../../utils/URL";
import { USERS, USERS_INIT_STATE, USERS_ERROR } from "./types";

export const loadDepHeads = () => async (dispatch) => {
   try {
      const response = await axios.get(URL + `/api/users/heads/`);

      dispatch({ type: USERS, payload: response.data });
   } catch (error) {
      if (error.response) {
         dispatch({
            type: USERS_ERROR,
            payload: {
               msg: error.response.data.message,
               status: error.response.status,
            },
         });
      } else {
         dispatch({
            type: USERS_ERROR,
            payload: {
               msg: error.message,
               status: error.status,
            },
         });
      }
   }
};

export const loadDnms = () => async (dispatch) => {
   try {
      const response = await axios.get(URL + `/api/users/d&ms/`);

      dispatch({ type: USERS, payload: response.data });
   } catch (error) {
      if (error.response) {
         dispatch({
            type: USERS_ERROR,
            payload: {
               msg: error.response.data.message,
               status: error.response.status,
            },
         });
      } else {
         dispatch({
            type: USERS_ERROR,
            payload: {
               msg: error.message,
               status: error.status,
            },
         });
      }
   }
};

export const loadUsersFromDep = (dep) => async (dispatch) => {
   try {
      const response = await axios.get(URL + `/api/users/dep/${dep}`);

      dispatch({ type: USERS, payload: response.data });
   } catch (error) {
      if (error.response) {
         dispatch({
            type: USERS_ERROR,
            payload: {
               msg: error.response.data.message,
               status: error.response.status,
            },
         });
      } else {
         dispatch({
            type: USERS_ERROR,
            payload: {
               msg: error.message,
               status: error.status,
            },
         });
      }
   }
};

export const loadUsersFromMyDep = () => async (dispatch) => {
   try {
      const response = await axios.get(URL + `/api/users/dep/mine/spec/`);

      dispatch({ type: USERS, payload: response.data });
   } catch (error) {
      if (error.response) {
         dispatch({
            type: USERS_ERROR,
            payload: {
               msg: error.response.data.message,
               status: error.response.status,
            },
         });
      } else {
         dispatch({
            type: USERS_ERROR,
            payload: {
               msg: error.message,
               status: error.status,
            },
         });
      }
   }
};

export const loadEmpsFromMyDep = () => async (dispatch) => {
   try {
      const response = await axios.get(URL + `/api/users/dep/mine/emp/`);

      dispatch({ type: USERS, payload: response.data });
   } catch (error) {
      if (error.response) {
         dispatch({
            type: USERS_ERROR,
            payload: {
               msg: error.response.data.message,
               status: error.response.status,
            },
         });
      } else {
         dispatch({
            type: USERS_ERROR,
            payload: {
               msg: error.message,
               status: error.status,
            },
         });
      }
   }
};

export const addUser =
   ({ toast, setLoad, formData, navigate }) =>
   async (dispatch) => {
      const { firstName, lastName, email, phone, role, department } = formData;
      const body = JSON.stringify({
         firstName,
         lastName,
         email,
         phone,
         role,
         department,
      });
      const config = {
         headers: {
            "Content-Type": "Application/json",
         },
      };
      try {
         await axios.post(URL + `/api/users/`, body, config);
         setLoad(false);
         toast.success("User Account Created Successfully", {
            autoClose: 5000,
         });
         dispatch({ type: USERS_INIT_STATE });
         navigate("/departments");
      } catch (error) {
         setLoad(false);
         toast.error(error.response.data.message, {
            autoClose: 5000,
         });
         dispatch({
            type: USERS_ERROR,
            payload: {
               msg: error.response.data.message,
               status: error.response.status,
            },
         });
      }
   };

export const changeUser =
   ({ toast, setLoad, id, data, navigate }) =>
   async (dispatch) => {
      const { role, status } = data;
      const body = JSON.stringify({
         role,
         status,
      });
      const config = {
         headers: {
            "Content-Type": "Application/json",
         },
      };
      try {
         await axios.put(URL + `/api/users/adminChange/${id}`, body, config);
         setLoad(false);
         toast.success("User Account Updated Successfully", {
            autoClose: 5000,
         });
         dispatch({ type: USERS_INIT_STATE });
         navigate("/departments");
      } catch (error) {
         setLoad(false);
         toast.error(error.response.data.message, {
            autoClose: 5000,
         });
         dispatch({
            type: USERS_ERROR,
            payload: {
               msg: error.response.data.message,
               status: error.response.status,
            },
         });
      }
   };

export const deleteUser =
   ({ toast, setLoad, id, navigate }) =>
   async (dispatch) => {
      try {
         const confirm = window.confirm(
            "Are you sure you want to remove this user account permanently?"
         );
         if (confirm) {
            await axios.delete(URL + `/api/users/${id}`);
            setLoad(false);
            toast.success("User Account Deleted Successfully", {
               autoClose: 5000,
            });
            dispatch({ type: USERS_INIT_STATE });
            navigate("/departments");
         }
      } catch (error) {
         setLoad(false);
         toast.error(error.response.data.message, {
            autoClose: 5000,
         });
         dispatch({
            type: USERS_ERROR,
            payload: {
               msg: error.response.data.message,
               status: error.response.status,
            },
         });
      }
   };
