import React from "react";
import TopBar from "../../components/TopBar";
import Sidebar from "../../components/Sidebar";

const DashboardEmp = (props) => {
   return (
      <>
         <div className="flex flex-col h-screen">
            <TopBar />
            <div className="flex flex-1 overflow-hidden">
               <Sidebar />
               <main className="flex-1 p-4 overflow-y-auto">Dashboard EMP</main>
            </div>
         </div>
      </>
   );
};

export default DashboardEmp;
