import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaTrash, FaEdit } from "react-icons/fa";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
   loadRegions,
   addRegion,
   deleteRegion,
   assignManager,
} from "../../redux/tasks/regionsT";
import { loadDnms } from "../../redux/tasks/usersT";
import Sidebar from "../../components/Sidebar";
import TopBar from "../../components/TopBar";
import Table from "../../components/Table";
import Spinner from "../../components/Spinner";

const Regions = ({
   loading,
   hLoading,
   heads,
   regions,
   loadRegions,
   addRegion,
   deleteRegion,
   loadDnms,
   assignManager,
}) => {
   const navigate = useNavigate();
   const [numberOptions] = useState([5, 10, 15, 20, 25, 30]);
   const [globalFilter, setGlobalFilter] = useState("");
   const [headModal, setHeadModal] = useState(false);
   const [regModal, setRegModal] = useState(false);
   const [load, setLoad] = useState(false);
   const [name, setName] = useState("");
   const [region, setRegion] = useState("");

   useEffect(() => {
      loadRegions();
      loadDnms();
   }, [loadRegions, loadDnms]);

   const handleData = (index) => {
      // setRegion(regions[index]);
      // setHeadModal(true);
   };

   const handleDOO = (e, reg) => {
      setRegion(reg);
      setHeadModal(true);
   };

   const handleManager = (e, reg) => {
      setRegion(reg);
      setHeadModal(true);
   };

   const handleModalClose = () => {
      setRegModal(false);
      setHeadModal(false);
   };

   const handleSubmit = () => {
      setLoad(true);
      addRegion(toast, setLoad, name, navigate);
      setRegModal(false);
      setName("");
   };

   const handleSelect = (e, user) => {
      setLoad(true);
      assignManager(toast, setLoad, region._id, user.role, user._id, navigate);
      setHeadModal(false);
      setRegion("");
   };

   const handleDelete = (e, _id) => {
      e.stopPropagation();
      setLoad(true);
      deleteRegion(toast, setLoad, _id, navigate);
   };

   const TableColumns = [
      {
         Header: "Region Name",
         accessor: "name",
      },
      {
         Header: "DOO",
         accessor: (row) =>
            `${row.DOO?.firstName || ""} ${row.DOO?.lastName || ""}`,
         Cell: ({ row, value }) => (
            <div className="flex flex-row justify-center items-center gap-x-3">
               <h1>{value}</h1>
               <button
                  className=" text-primary rounded-md cursor-pointer hover:text-neutral-400"
                  onClick={(e) => handleDOO(e, row.original)}
               >
                  <FaEdit />
               </button>
            </div>
         ),
      },
      {
         Header: "Manager",
         accessor: (row) =>
            `${row.Manager?.firstName || ""} ${row.Manager?.lastName || ""}`,
         Cell: ({ row, value }) => (
            <div className="flex flex-row justify-center items-center gap-x-3">
               <h1>{value}</h1>
               <button
                  className=" text-primary rounded-md cursor-pointer hover:text-neutral-400"
                  onClick={(e) => handleManager(e, row.original)}
               >
                  <FaEdit />
               </button>
            </div>
         ),
      },
      {
         Header: "Delete",
         accessor: "_id",
         Cell: ({ value }) => (
            <button
               className="bg-sysRed text-white p-2 rounded-md cursor-pointer"
               onClick={(e) => handleDelete(e, value)}
            >
               <FaTrash />
            </button>
         ),
      },
   ];

   return load || loading || hLoading ? (
      <Spinner />
   ) : (
      <>
         <div className="flex flex-col h-screen">
            <TopBar />
            <div className="flex flex-1 overflow-hidden">
               <Sidebar />
               <main className="flex-1 p-4 overflow-y-auto">
                  <div className=" mx-auto">
                     <div className="flex flex-col gap-x-6 sm:flex-row sm:items-center sm:justify-end space-y-2 sm:space-y-0 sm:space-x-2 p-4">
                        <button
                           onClick={(e) => setRegModal(!regModal)}
                           className="bg-sysRed hover:bg-red-600 font-semibold text-white py-2 px-4 rounded sm:order-2"
                        >
                           Add Region
                        </button>
                     </div>
                     {regions?.length > 0 ? (
                        <Table
                           data={regions}
                           columns={TableColumns}
                           numberOptions={numberOptions}
                           dataFun={handleData}
                           globalFilter={globalFilter}
                           setGlobalFilter={setGlobalFilter}
                        />
                     ) : (
                        <>
                           <div className="flex justify-center mt-20 text-2xl font-semibold">
                              No Regions Found!
                           </div>
                        </>
                     )}
                  </div>
               </main>
            </div>
         </div>
         <Modal
            isOpen={regModal}
            onClose={handleModalClose}
            handleSubmit={handleSubmit}
            name={name}
            setName={setName}
         />
         <HeadModal
            isOpen={headModal}
            onClose={handleModalClose}
            users={heads}
            handleSelect={handleSelect}
         />
      </>
   );
};

Regions.propTypes = {
   loading: PropTypes.bool.isRequired,
   hLoading: PropTypes.bool.isRequired,
   regions: PropTypes.array,
   heads: PropTypes.array,
   loadRegions: PropTypes.func.isRequired,
   addRegion: PropTypes.func.isRequired,
   deleteRegion: PropTypes.func.isRequired,
   loadDnms: PropTypes.func.isRequired,
   assignManager: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
   loading: state.regions.loading,
   regions: state.regions.regions,
   hLoading: state.users.loading,
   heads: state.users.users,
});

export default connect(mapStateToProps, {
   loadRegions,
   addRegion,
   deleteRegion,
   loadDnms,
   assignManager,
})(Regions);

const Modal = ({ isOpen, onClose, handleSubmit, name, setName }) => {
   if (!isOpen) return null;

   return (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
         <div className="bg-white rounded-lg shadow-lg p-6 py-10 w-full max-w-md mx-auto flex flex-col justify-center items-center gap-y-10">
            <h2 className="text-2xl font-semibold">Add New Region</h2>
            <input
               type="text"
               className="w-2/3 p-2 border border-gray-300 rounded"
               placeholder="Enter region name"
               value={name}
               onChange={(e) => setName(e.target.value)}
            />
            <div className="flex justify-end">
               <button
                  onClick={onClose}
                  className="bg-sysRed text-white py-2 px-4 rounded hover:bg-neutral-600 mr-2"
               >
                  Cancel
               </button>
               <button
                  onClick={handleSubmit}
                  className="bg-primary text-white py-2 px-4 rounded hover:bg-neutral-600"
               >
                  Add
               </button>
            </div>
         </div>
      </div>
   );
};

const HeadModal = ({ isOpen, onClose, users, handleSelect }) => {
   if (!isOpen) return null;

   return (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
         <div className="bg-white rounded-lg shadow-lg p-6 py-10 w-full max-w-7xl mx-auto flex flex-col gap-y-6">
            <h2 className="text-2xl font-semibold text-center">
               Select a Manager
            </h2>
            <div className="overflow-y-auto max-h-64 w-full">
               <ul className="divide-y divide-gray-200">
                  <li className="py-4 flex items-center justify-around ">
                     <div className="flex flex-row w-full justify-around items-center">
                        <p className="text-sm font-medium flex-1 text-center ">
                           Name
                        </p>
                        <p className="text-sm font-medium flex-1 text-center">
                           Role
                        </p>
                        <p className="text-sm font-medium flex-1 text-center">
                           Department
                        </p>
                     </div>
                     <button className=" text-white py-1 px-6 rounded hover:bg-neutral-600"></button>
                  </li>
                  {users.map((user) => (
                     <li
                        key={user._id}
                        className="py-4 flex items-center justify-around"
                     >
                        <div className="flex flex-row w-full justify-around items-center">
                           <p className="text-md font-medium flex-1 text-center">
                              {user.firstName}
                           </p>
                           <p className="text-md font-medium flex-1 text-center">
                              {user.role}
                           </p>
                           <p className="text-md font-medium flex-1 text-center">
                              {user.department}
                           </p>
                        </div>
                        <button
                           className="bg-primary text-white py-1 px-3 rounded hover:bg-neutral-600"
                           onClick={(e) => handleSelect(e, user)}
                        >
                           Select
                        </button>
                     </li>
                  ))}
               </ul>
            </div>
            <div className="flex justify-center">
               <button
                  onClick={onClose}
                  className="bg-sysRed text-white py-2 px-4 rounded hover:bg-red-600"
               >
                  Close
               </button>
            </div>
         </div>
      </div>
   );
};
