import {
   HOTELS,
   HOTELS_ERROR,
   HOTELS_INIT_STATE,
   LOGOUT,
} from "../tasks/types";

const initialState = {
   loading: true,
   hotels: null,
   error: null,
};

export default function authR(state = initialState, action) {
   const { type, payload } = action;
   switch (type) {
      case HOTELS:
         return {
            ...state,
            loading: false,
            hotels: payload.hotels,
         };
      case HOTELS_ERROR:
         return {
            ...state,
            loading: false,
            error: payload,
         };
      case HOTELS_INIT_STATE:
         return initialState;
      case LOGOUT:
         return initialState;
      default:
         return state;
   }
}
