import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";

const AdminRoutes = ({ children, loading, user }) =>
   !loading && user.role !== "EMPLOYEE" ? <Navigate to="/" /> : children;

AdminRoutes.propTypes = {
   loading: PropTypes.bool.isRequired,
   user: PropTypes.object,
};

const mapStateToProps = (state) => ({
   loading: state.auth.loading,
   user: state.auth.user,
});

export default connect(mapStateToProps)(AdminRoutes);
