import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import PropTypes from "prop-types";

import gmail from "../assets/gmail.png";
import amerilodgeLogo from "../assets/amerilodge logo.png";
import { login } from "../redux/tasks/authT";
import { useNavigate } from "react-router-dom";
import Spinner from "../components/Spinner";
import { URL } from "../utils/URL";

const Login = ({ login, user, loading }) => {
  const urlParams = new URLSearchParams(window.location.search);

  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    let error = null;
    if (error) {
      error = null;
    } else {
      error = urlParams.get("error");
      toast.error(error);
    }

    if (!loading) {
      setLoad(false);
      if (user) {
        if (user.role === "EMPLOYEE") {
          navigate("/open-tickets-emp");
        } else if (user.role === "ADMIN") {
          navigate("/admin-dashboard");
        } else if (
          user.role === "DEPHEAD" ||
          user.role === "DOO" ||
          user.role === "MANAGER"
        ) {
          navigate("/dephead-dashboard");
        } else if (user.role === "ENDUSER") {
          navigate("/user-dashboard");
        }
      }
    }
    //eslint-disable-next-line
  }, [loading, user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoad(true);
    login(setLoad, toast, formData);
  };

  const handleGoogleLogin = () => {
    window.location.href = `${URL}/api/auth/auth/google`;
  };

  return load ? (
    <Spinner />
  ) : (
    <>
      <div className="flex flex-col md:flex-row h-screen">
        <div className="md:w-1/2 flex flex-col justify-center items-center bg-gray-100 p-8">
          <img
            src={amerilodgeLogo}
            alt="Amerilodge Group"
            className="w-80 mb-4"
          />
          <h1 className="text-3xl font-bold mb-2">Welcome Back!</h1>
          <p className="text-gray-600 mb-8">
            To keep connected with us please login with your personal info.
          </p>
        </div>
        <div className="md:w-1/2 flex flex-col justify-center items-center bg-primary text-white p-8">
          <h2 className="text-3xl font-bold mb-6">Sign In</h2>
          <img
            src={gmail}
            alt="Google"
            className="w-28 mb-4 cursor-pointer"
            onClick={handleGoogleLogin}
          />
          <form className="w-full max-w-sm" onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-sm font-bold mb-2" htmlFor="email">
                Email
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="email"
                type="email"
                placeholder="Email"
                value={formData.email}
                name="email"
                onChange={handleChange}
              />
            </div>
            <div className="mb-6">
              <label
                className="block text-sm font-bold mb-2"
                htmlFor="password"
              >
                Password
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                id="password"
                type="password"
                placeholder="Password"
                value={formData.password}
                name="password"
                onChange={handleChange}
              />
              {/* <p className="text-sm text-right text-blue-500 hover:text-blue-700">
                        Forgot your Password?
                     </p> */}
            </div>
            <div className="flex items-center justify-center">
              <button
                className="bg-sysRed hover:bg-red-700 cursor-pointer text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit"
              >
                Sign In
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

Login.propTypes = {
  loading: PropTypes.bool.isRequired,
  user: PropTypes.object,
  login: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.auth.loading,
  user: state.auth.user,
});

export default connect(mapStateToProps, { login })(Login);
