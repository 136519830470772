import axios from "axios";

import { URL } from "../../utils/URL";
import { DEPARTMENTS, DEPARTMENTS_ERROR, DEPARTMENTS_SINGLE } from "./types";

export const loadDepartments = () => async (dispatch) => {
   try {
      const response = await axios.get(URL + "/api/departments/");

      dispatch({ type: DEPARTMENTS, payload: response.data });
   } catch (error) {
      if (error.response) {
         dispatch({
            type: DEPARTMENTS_ERROR,
            payload: {
               msg: error.response.data.message,
               status: error.response.status,
            },
         });
      } else {
         dispatch({
            type: DEPARTMENTS_ERROR,
            payload: {
               msg: error.message,
               status: error.status,
            },
         });
      }
   }
};

export const loadMyDep = () => async (dispatch) => {
   try {
      const response = await axios.get(URL + "/api/departments/mine/");

      dispatch({ type: DEPARTMENTS_SINGLE, payload: response.data });
   } catch (error) {
      if (error.response) {
         dispatch({
            type: DEPARTMENTS_ERROR,
            payload: {
               msg: error.response.data.message,
               status: error.response.status,
            },
         });
      } else {
         dispatch({
            type: DEPARTMENTS_ERROR,
            payload: {
               msg: error.message,
               status: error.status,
            },
         });
      }
   }
};

export const addDepartment =
   (toast, setLoad, departmentName, categories, navigate) =>
   async (dispatch) => {
      try {
         const body = JSON.stringify({ departmentName, categories });
         const config = {
            headers: {
               "Content-Type": "Application/json",
            },
         };

         const res = await axios.post(URL + `/api/departments/`, body, config);

         dispatch({
            type: DEPARTMENTS,
            payload: res.data,
         });
         toast.success("Department added Successfully", {
            autoClose: 5000,
         });
         setLoad(false);

         navigate("/departments");
      } catch (e) {
         setLoad(false);
         toast.error(e.response.data.message, {
            autoClose: 5000,
         });
         dispatch({
            type: DEPARTMENTS_ERROR,
            payload: e.response.data,
         });
      }
   };

export const updateCats =
   (toast, setLoad, id, categories, navigate) => async (dispatch) => {
      try {
         const body = JSON.stringify({ categories });
         const config = {
            headers: {
               "Content-Type": "Application/json",
            },
         };

         const res = await axios.put(
            URL + `/api/departments/changeCategories/${id}`,
            body,
            config
         );

         setLoad(false);

         dispatch({
            type: DEPARTMENTS,
            payload: res.data,
         });
         toast.success("Department updated Successfully", {
            autoClose: 5000,
         });

         navigate("/departments");
      } catch (e) {
         setLoad(false);
         toast.error(e.response.data.message, {
            autoClose: 5000,
         });
         dispatch({
            type: DEPARTMENTS_ERROR,
            payload: e.response.data,
         });
      }
   };

export const changeAAUsers =
   (toast, setLoad, id, categories, navigate) => async (dispatch) => {
      try {
         const body = JSON.stringify({ categories });
         const config = {
            headers: {
               "Content-Type": "Application/json",
            },
         };

         const res = await axios.put(
            URL + `/api/departments/AAUser/${id}`,
            body,
            config
         );

         setLoad(false);

         dispatch({
            type: DEPARTMENTS_SINGLE,
            payload: res.data,
         });
         toast.success("Settings  Changed Successfully", {
            autoClose: 5000,
         });

         navigate("/depHead-dashboard");
      } catch (e) {
         setLoad(false);
         toast.error(e.response.data.message, {
            autoClose: 5000,
         });
         dispatch({
            type: DEPARTMENTS_ERROR,
            payload: e.response.data,
         });
      }
   };

export const deleteDep = (toast, setLoad, id, navigate) => async (dispatch) => {
   try {
      const res = await axios.delete(URL + `/api/departments/${id}`);

      dispatch({
         type: DEPARTMENTS,
         payload: res.data,
      });

      toast.success("Department deleted Successfully", {
         autoClose: 5000,
      });

      setLoad(false);

      navigate("/departments");
   } catch (e) {
      setLoad(false);
      toast.error(e.response.data.message, {
         autoClose: 5000,
      });
      dispatch({
         type: DEPARTMENTS_ERROR,
         payload: e.response.data,
      });
   }
};
