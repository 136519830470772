import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { updateCats, deleteDep } from "../../redux/tasks/departmentsT";
import TopBar from "../../components/TopBar";
import Sidebar from "../../components/Sidebar";
import { toast } from "react-toastify";
import Spinner from "../../components/Spinner";

const DepartmentSingle = ({ updateCats, deleteDep }) => {
   const navigate = useNavigate();
   const location = useLocation();
   const { dep } = location.state;
   const [load, setLoad] = useState(false);
   const [category, setCategory] = useState("");
   const [categories, setCategories] = useState([]);
   const isFirstRender = useRef(true);

   useEffect(() => {
      if (isFirstRender.current) {
         // Only run the effect if it's the first render
         if (dep && dep.categories) {
            dep.categories.forEach((cat) => {
               setCategories((prevCategories) => [
                  ...prevCategories,
                  cat.categoryName,
               ]);
            });
         }
         isFirstRender.current = false; // Set the flag to false after the first run
      }
   }, [dep]);

   const handleAddCategory = () => {
      if (category) {
         setCategories([...categories, category]);
         setCategory("");
      }
   };

   const handleRemoveCat = (index) => {
      setCategories(categories.filter((_, i) => i !== index));
   };

   const handleSubmit = (e) => {
      e.preventDefault();
      setLoad(true);
      updateCats(toast, setLoad, dep._id, categories, navigate);
   };

   const handleDelete = (e) => {
      e.preventDefault();
      if (
         window.confirm(
            "Are you sure you want to delete this department? you will have to change or remove this department's employees and tickets too."
         )
      ) {
         setLoad(true);
         deleteDep(toast, setLoad, dep._id, navigate);
      }
   };

   return load ? (
      <Spinner />
   ) : (
      <div className="flex flex-col h-screen">
         <TopBar />
         <div className="flex flex-1 overflow-hidden">
            <Sidebar />
            <main className="flex-1 p-4 pt-10 overflow-y-auto">
               <div className="flex flex-col h-full items-center">
                  <h1 className="text-3xl font-bold mb-6 text-center">
                     Department Details
                  </h1>
                  <div className="flex flex-col w-full max-w-lg mt-20 gap-y-5">
                     <div className="flex justify-between items-center mb-4">
                        <label className="font-semibold">Department Name</label>
                        <span className="border-b w-2/3 text-center">
                           {dep.departmentName}
                        </span>
                     </div>
                     <div className="flex justify-between items-center mb-4">
                        <label className="font-semibold">Add Category</label>
                        <div className="flex w-2/3">
                           <input
                              type="text"
                              className="flex-grow p-2 border rounded-l"
                              placeholder="Text Here...."
                              value={category}
                              onChange={(e) => setCategory(e.target.value)}
                           />
                           <button
                              onClick={handleAddCategory}
                              className="bg-primary text-white p-2 rounded-r"
                           >
                              +
                           </button>
                        </div>
                     </div>
                     {categories?.length > 0 && (
                        <div className="mb-4">
                           <h3 className="font-semibold mb-2">Categories:</h3>
                           <ul>
                              {categories.map((category, index) => (
                                 <li
                                    key={index}
                                    className="flex justify-between items-center mb-2"
                                 >
                                    <span>{category}</span>
                                    <button
                                       onClick={() => handleRemoveCat(index)}
                                       className="text-red-600 hover:text-secondry font-semibold"
                                    >
                                       Remove
                                    </button>
                                 </li>
                              ))}
                           </ul>
                        </div>
                     )}
                  </div>
                  <div className="flex w-full max-w-2xl justify-around mt-10">
                     <button
                        onClick={() => navigate(-1)}
                        className="bg-neutral-700 hover:bg-neutral-500 font-semibold text-white w-1/4 py-2 rounded"
                     >
                        Go Back
                     </button>
                     <button
                        onClick={(e) => handleDelete(e)}
                        className="bg-sysRed hover:bg-neutral-500 font-semibold text-white w-1/4 py-2 rounded"
                     >
                        Delete Department
                     </button>
                     <button
                        onClick={(e) => handleSubmit(e)}
                        className="bg-primary hover:bg-neutral-500 font-semibold text-white w-1/4 py-2 rounded"
                     >
                        Update
                     </button>
                  </div>
               </div>
            </main>
         </div>
      </div>
   );
};

DepartmentSingle.propTypes = {
   updateCats: PropTypes.func.isRequired,
   deleteDep: PropTypes.func.isRequired,
};

export default connect(null, { updateCats, deleteDep })(DepartmentSingle);
