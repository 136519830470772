import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Table from "../../components/Table";
import Spinner from "../../components/Spinner";
import TopBar from "../../components/TopBar";
import Sidebar from "../../components/Sidebar";
import { loadOpenTicketsU } from "../../redux/tasks/ticketsT";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";

const TableColumns = [
   {
      Header: "#",
      accessor: "ticketNo",
   },
   {
      Header: "Subject",
      accessor: "subject",
   },
   {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => {
         return value === "RESOLVED"
            ? "Resolved"
            : value === "MARKEDASRESOLVED"
            ? "Marked as Resolved"
            : value === "WORKINPROGRESS"
            ? "Work in Progress"
            : "Un-Assigned";
      },
   },
   {
      Header: "Created On",
      accessor: "createdAt",
      Cell: ({ value }) => {
         return format(new Date(value), "MM-dd-yyyy");
      },
   },
];

const OpenTickets = ({ loading, tickets, loadOpenTicketsU }) => {
   const navigate = useNavigate();
   const [numberOptions] = useState([5, 10, 15, 20, 25, 30]);

   useEffect(() => {
      loadOpenTicketsU();
   }, [loadOpenTicketsU]);

   const handleData = (index) => {
      const ticket = tickets[index];
      navigate("/single-ticket", { state: { ticket } });
   };

   return loading ? (
      <Spinner />
   ) : (
      <div className="flex flex-col h-screen">
         <TopBar />
         <div className="flex flex-1 overflow-hidden">
            <Sidebar />
            <main className="flex-1 p-4 overflow-y-auto">
               <div className=" mx-auto p-4">
                  <h2 className="text-3xl font-bold text-center mb-6">
                     Your Open Tickets
                  </h2>
                  {tickets?.length > 0 ? (
                     <Table
                        data={tickets}
                        columns={TableColumns}
                        numberOptions={numberOptions}
                        dataFun={handleData}
                     />
                  ) : (
                     <>
                        <h4 className="text-xl text-center my-20">
                           No Open Tickets found
                        </h4>
                     </>
                  )}
               </div>
            </main>
         </div>
      </div>
   );
};

OpenTickets.propTypes = {
   loading: PropTypes.bool.isRequired,
   tickets: PropTypes.array,
   loadOpenTicketsU: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
   loading: state.tickets.openTicketsLoading,
   tickets: state.tickets.openTickets,
});

export default connect(mapStateToProps, { loadOpenTicketsU })(OpenTickets);
