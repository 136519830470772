import Login from "../pages/Login";
import AdminRoutes from "./AdminRoutes";
import Hotels from "../pages/admin/Hotels";
import Test from "../pages/GoogleCallback";
import DepheadRoutes from "./DepheadRoutes";
import Tickets from "../pages/admin/Tickets";
import Regions from "../pages/admin/Regions";
import EmployeeRoutes from "./EmployeeRoutes";
import ProtectedRoutes from "./ProtectedRoutes";
import SingleTicket from "../pages/SingleTicket";
import Settings from "../pages/depHead/Settings";
import UserSettings from "../pages/UserSettings";
import Departments from "../pages/admin/Departments";
import HotelAddNew from "../pages/admin/HotelAddNew";
import HotelDetails from "../pages/admin/HotelDetails";
import OpenTickets from "../pages/endUser/OpenTickets";
import TicketsOpen from "../pages/depHead/TicketsOpen";
import TicketsOpenE from "../pages/employee/TicketsOpenE";
import DashboardEmp from "../pages/employee/DashboardEmp";
import ClosedTickets from "../pages/endUser/ClosedTickets";
import DashboardAdmin from "../pages/admin/DashboardAdmin";
import DashboardUser from "../pages/endUser/DashboardUser";
import TicketsClosed from "../pages/depHead/TicketsClosed";
import EmployeeDetails from "../pages/admin/EmployeeDetails";
import TicketsClosedE from "../pages/employee/TicketsClosedE";
import DepartmentAddNew from "../pages/admin/DepartmentAddNew";
import DepartmentSingle from "../pages/admin/DepartmentSingle";
import CreateNewTicket from "../pages/endUser/CreateNewTicket";
import EmployeeRegister from "../pages/admin/EmployeeRegister";
import DashboardDepHead from "../pages/depHead/DashboardDepHead";
import TicketsUnAssigned from "../pages/depHead/TicketsUnAssigned";
import DepartmentEmployees from "../pages/admin/DepartmentEmployees";
import TicketsEscalated from "../pages/depHead/TicketsEscalated";

const routes = [
   { path: "/", component: <Login /> },
   { path: "/auth/google/callback", component: <Test /> },
   {
      path: "/user-settings",
      component: (
         <ProtectedRoutes>
            <UserSettings />
         </ProtectedRoutes>
      ),
   },
   {
      path: "/user-dashboard",
      component: (
         <ProtectedRoutes>
            <DashboardUser />
         </ProtectedRoutes>
      ),
   },
   {
      path: "/new-ticket",
      component: (
         <ProtectedRoutes>
            <CreateNewTicket />
         </ProtectedRoutes>
      ),
   },
   {
      path: "/open-tickets",
      component: (
         <ProtectedRoutes>
            <OpenTickets />
         </ProtectedRoutes>
      ),
   },
   {
      path: "/closed-tickets",
      component: (
         <ProtectedRoutes>
            <ClosedTickets />
         </ProtectedRoutes>
      ),
   },
   {
      path: "/single-ticket",
      component: (
         <ProtectedRoutes>
            <SingleTicket />
         </ProtectedRoutes>
      ),
   },
   {
      path: "/employee-dashboard",
      component: (
         <ProtectedRoutes>
            <EmployeeRoutes>
               <DashboardEmp />
            </EmployeeRoutes>
         </ProtectedRoutes>
      ),
   },
   {
      path: "/open-tickets-emp",
      component: (
         <ProtectedRoutes>
            <EmployeeRoutes>
               <TicketsOpenE />
            </EmployeeRoutes>
         </ProtectedRoutes>
      ),
   },
   {
      path: "/closed-tickets-emp",
      component: (
         <ProtectedRoutes>
            <EmployeeRoutes>
               <TicketsClosedE />
            </EmployeeRoutes>
         </ProtectedRoutes>
      ),
   },
   {
      path: "/dephead-dashboard",
      component: (
         <ProtectedRoutes>
            <DepheadRoutes>
               <DashboardDepHead />
            </DepheadRoutes>
         </ProtectedRoutes>
      ),
   },
   {
      path: "/escalated-tickets",
      component: (
         <ProtectedRoutes>
            <DepheadRoutes>
               <TicketsEscalated />
            </DepheadRoutes>
         </ProtectedRoutes>
      ),
   },
   {
      path: "/unassigned-tickets",
      component: (
         <ProtectedRoutes>
            <DepheadRoutes>
               <TicketsUnAssigned />
            </DepheadRoutes>
         </ProtectedRoutes>
      ),
   },
   {
      path: "/open-tickets-dep",
      component: (
         <ProtectedRoutes>
            <DepheadRoutes>
               <TicketsOpen />
            </DepheadRoutes>
         </ProtectedRoutes>
      ),
   },
   {
      path: "/closed-tickets-dep",
      component: (
         <ProtectedRoutes>
            <DepheadRoutes>
               <TicketsClosed />
            </DepheadRoutes>
         </ProtectedRoutes>
      ),
   },
   {
      path: "/aa-users",
      component: (
         <ProtectedRoutes>
            <DepheadRoutes>
               <Settings />
            </DepheadRoutes>
         </ProtectedRoutes>
      ),
   },
   {
      path: "/admin-dashboard",
      component: (
         <ProtectedRoutes>
            <AdminRoutes>
               <DashboardAdmin />
            </AdminRoutes>
         </ProtectedRoutes>
      ),
   },
   {
      path: "/departments",
      component: (
         <ProtectedRoutes>
            <AdminRoutes>
               <Departments />
            </AdminRoutes>
         </ProtectedRoutes>
      ),
   },
   {
      path: "/department-add-new",
      component: (
         <ProtectedRoutes>
            <AdminRoutes>
               <DepartmentAddNew />
            </AdminRoutes>
         </ProtectedRoutes>
      ),
   },
   {
      path: "/department-single",
      component: (
         <ProtectedRoutes>
            <AdminRoutes>
               <DepartmentSingle />
            </AdminRoutes>
         </ProtectedRoutes>
      ),
   },
   {
      path: "/department-employees",
      component: (
         <ProtectedRoutes>
            <AdminRoutes>
               <DepartmentEmployees />
            </AdminRoutes>
         </ProtectedRoutes>
      ),
   },
   {
      path: "/employee-register",
      component: (
         <ProtectedRoutes>
            <AdminRoutes>
               <EmployeeRegister />
            </AdminRoutes>
         </ProtectedRoutes>
      ),
   },
   {
      path: "/employee-details",
      component: (
         <ProtectedRoutes>
            <AdminRoutes>
               <EmployeeDetails />
            </AdminRoutes>
         </ProtectedRoutes>
      ),
   },
   {
      path: "/tickets-all",
      component: (
         <ProtectedRoutes>
            <AdminRoutes>
               <Tickets />
            </AdminRoutes>
         </ProtectedRoutes>
      ),
   },
   {
      path: "/hotels",
      component: (
         <ProtectedRoutes>
            <AdminRoutes>
               <Hotels />
            </AdminRoutes>
         </ProtectedRoutes>
      ),
   },
   {
      path: "/hotel-add-new",
      component: (
         <ProtectedRoutes>
            <AdminRoutes>
               <HotelAddNew />
            </AdminRoutes>
         </ProtectedRoutes>
      ),
   },
   {
      path: "/hotel-details",
      component: (
         <ProtectedRoutes>
            <AdminRoutes>
               <HotelDetails />
            </AdminRoutes>
         </ProtectedRoutes>
      ),
   },
   {
      path: "/regions",
      component: (
         <ProtectedRoutes>
            <AdminRoutes>
               <Regions />
            </AdminRoutes>
         </ProtectedRoutes>
      ),
   },
];

export default routes;
