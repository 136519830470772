import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import printer1 from "../../assets/printer-1.png";
import printer2 from "../../assets/printer-2.png";
import ctrix from "../../assets/Citrix Workspace-1.png";
import { loadDepartments } from "../../redux/tasks/departmentsT";
import { loadHotels } from "../../redux/tasks/hotelsT";
import { addTicket } from "../../redux/tasks/ticketsT";
import TopBar from "../../components/TopBar";
import Sidebar from "../../components/Sidebar";
import Spinner from "../../components/Spinner";

const CreateNewTicket = ({
   hotelLoading,
   depLoading,
   hotels,
   departments,
   loadHotels,
   loadDepartments,
   addTicket,
}) => {
   const navigate = useNavigate();
   const [cats, setCats] = useState(null);
   const [load, setLoad] = useState(false);
   const [detailedFields, setDetailedFields] = useState({});
   const [trouble, setTrouble] = useState(false);
   const [formData, setFormData] = useState({
      hotel: "",
      department: "",
      category: "",
      priority: "",
      issues: "",
      // commentText: "",
      detailedFields: {},
      attachment: null,
   });

   useEffect(() => {
      loadHotels();
      loadDepartments();
   }, [loadHotels, loadDepartments]);

   const handleChange = (e) => {
      const { name, value, files } = e.target;
      if (name === "department") {
         const selDep = departments.find((d) => d.departmentName === value);
         setCats(selDep?.categories);
         setFormData({
            ...formData,
            [name]: value,
         });
      } else {
         setFormData({
            ...formData,
            [name]: files ? Array.from(files) : value,
         });
      }
   };

   const handleInputChange = (e) => {
      const { name, value } = e.target;
      setDetailedFields({ ...detailedFields, [name]: value });
   };

   // const handleCheckboxChange = (e) => {
   //    const { name, checked } = e.target;
   //    const [group, option] = name.split(".");

   //    setDetailedFields((prevState) => ({
   //       ...prevState,
   //       [group]: {
   //          ...prevState[group],
   //          [option]: checked,
   //       },
   //    }));
   // };

   const handleSubmit = (e) => {
      e.preventDefault();
      if (formData.department === "IT") {
         if (trouble) {
            setFormData({ ...formData, detailedFields: detailedFields });

            const data = new FormData();
            if (formData.attachment && formData.attachment.length > 0) {
               formData.attachment.forEach((file, index) => {
                  data.append(`attachment`, file);
               });
            }

            // Append other form data fields if any
            Object.keys(formData).forEach((key) => {
               if (key !== "attachment") {
                  data.append(key, formData[key]);
               }
            });
            setLoad(true);
            addTicket(toast, setLoad, data, navigate);
         }
         setTrouble(!trouble);
      } else {
         let hasError = false;
         Object.entries(detailedFields).forEach(([key, value]) => {
            if (key.endsWith("Qty")) {
            } else {
               if (detailedFields[key + "Qty"]) {
               } else {
                  toast.error(`${key} Quantity field must not be 0`);
                  hasError = true;
                  return;
               }
            }
         });

         if (hasError) {
            return;
         }

         const updatedFormData = {
            ...formData,
            detailedFields: JSON.stringify(detailedFields),
         };

         const data = new FormData();
         if (formData.attachment && formData.attachment.length > 0) {
            formData.attachment.forEach((file, index) => {
               data.append(`attachment`, file);
            });
         }

         // Append other form data fields if any
         Object.keys(updatedFormData).forEach((key) => {
            if (key !== "attachment") {
               data.append(key, updatedFormData[key]);
            }
         });
         setLoad(true);
         addTicket(toast, setLoad, data, navigate);
         console.log(detailedFields);
      }
   };

   return trouble ? (
      <>
         <div className="flex flex-col h-screen">
            <TopBar />
            <div className="flex flex-1 overflow-hidden">
               <Sidebar />
               <main className="flex-1 p-4 overflow-y-auto">
                  <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center p-4">
                     <div className="max-w-4xl w-full bg-white shadow-md rounded-lg p-6">
                        <img
                           src={printer1}
                           alt="Troubleshooting instructions"
                           className="w-full mb-6"
                        />
                        <img
                           src={printer2}
                           alt="Troubleshooting instructions"
                           className="w-full mb-6"
                        />
                        <img
                           src={ctrix}
                           alt="Troubleshooting instructions"
                           className="w-full mb-6"
                        />
                     </div>
                     <div className="flex justify-end gap-x-10 mt-6">
                        <button
                           onClick={(e) => navigate(-1)}
                           className="bg-sysRed hover:bg-neutral-600 font-semibold text-white px-4 py-2 rounded"
                        >
                           Cancel
                        </button>
                        <button
                           onClick={handleSubmit}
                           className="bg-primary hover:bg-neutral-600 font-semibold text-white px-4 py-2 rounded mr-2"
                        >
                           Proceed with Ticket
                        </button>
                     </div>
                  </div>
               </main>
            </div>
         </div>
      </>
   ) : hotelLoading || depLoading || load ? (
      <Spinner />
   ) : (
      <>
         <div className="flex flex-col h-screen">
            <TopBar />
            <div className="flex flex-1 overflow-hidden">
               <Sidebar />
               <main className="flex-1 p-4 overflow-y-auto">
                  <div className="max-w-4xl mx-auto p-4">
                     <h2 className="text-3xl font-bold text-center mb-6">
                        New Ticket
                     </h2>
                     <form onSubmit={handleSubmit} className="space-y-4">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                           <div>
                              <label className="block mb-1">
                                 Select Your Hotel
                                 <span className="text-red-500">*</span>
                              </label>
                              <select
                                 name="hotel"
                                 value={formData.hotel}
                                 onChange={handleChange}
                                 className="w-full p-2 border rounded"
                                 required
                              >
                                 <option value="">Select Here</option>
                                 {hotels?.map((h) => (
                                    <option key={h._id} value={h.name}>
                                       {h.name}
                                    </option>
                                 ))}
                              </select>
                           </div>
                           <div>
                              <label className="block mb-1">
                                 Choose Dept.
                                 <span className="text-red-500">*</span>
                              </label>
                              <select
                                 name="department"
                                 value={formData.department}
                                 onChange={handleChange}
                                 className="w-full p-2 border rounded"
                                 required
                              >
                                 <option value="">Select Here</option>
                                 {departments?.map((d) => (
                                    <option
                                       key={d._id}
                                       value={d.departmentName}
                                    >
                                       {d.departmentName}
                                    </option>
                                 ))}
                              </select>
                           </div>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                           <div>
                              <label className="block mb-1">
                                 Category<span className="text-red-500">*</span>
                              </label>
                              <select
                                 name="category"
                                 value={formData.category}
                                 onChange={handleChange}
                                 className="w-full p-2 border rounded"
                                 required
                              >
                                 <option value="">Select Here</option>
                                 {cats?.map((c) => (
                                    <option
                                       key={c.categoryName}
                                       value={c.categoryName}
                                    >
                                       {c.categoryName}
                                    </option>
                                 ))}
                              </select>
                           </div>
                           <div>
                              <label className="block mb-1">
                                 Priority<span className="text-red-500">*</span>
                              </label>
                              <select
                                 name="priority"
                                 value={formData.priority}
                                 onChange={handleChange}
                                 className="w-full p-2 border rounded"
                                 required
                              >
                                 <option value="">Select Here</option>
                                 <option value="LOW">Low</option>
                                 <option value="MEDIUM">Medium</option>
                                 <option value="HIGH">High</option>
                              </select>
                           </div>
                        </div>
                        {/* <div>
                           <label className="block mb-1">
                              Subject<span className="text-red-500">*</span>
                           </label>
                           <input
                              type="text"
                              name="subject"
                              value={formData.subject}
                              onChange={handleChange}
                              className="w-full p-2 border rounded"
                              required
                           />
                        </div> */}
                        {formData.category === "Food and Beverage" ? (
                           <>
                              <div className="p-4 mx-auto">
                                 <h2 className="text-xl font-bold mb-4">
                                    Rational Oven Tabs
                                 </h2>

                                 {["redTab", "greenTab", "blueTab"].map(
                                    (tab, index) => (
                                       <div
                                          key={tab}
                                          className="flex items-center mb-4"
                                       >
                                          <div className="flex-grow">
                                             <label className="block mb-1">
                                                {index === 0
                                                   ? "Red Cleaning Tab / Ovens Prior to 2024"
                                                   : index === 1
                                                   ? "Green Cleaning Tab / Ovens 2024 forward"
                                                   : "Blue Descaling Tab / All Ovens"}
                                             </label>
                                          </div>
                                          <div className="flex items-center">
                                             <label className="block mb-1 mr-2">
                                                Quantity
                                                <span className="text-red-500">
                                                   *
                                                </span>
                                             </label>
                                             <input
                                                type="number"
                                                name={tab}
                                                value={detailedFields[tab] || 0}
                                                onChange={handleInputChange}
                                                className="w-16 text-center border px-2 py-1"
                                                min="0"
                                             />
                                          </div>
                                       </div>
                                    )
                                 )}

                                 {[
                                    "Equipment",
                                    "Supplies",
                                    "Miscellaneous",
                                 ].map((item, index) => (
                                    <div
                                       key={item}
                                       className="flex items-center mb-4"
                                    >
                                       <div className="flex-grow">
                                          <label className="block mb-1 capitalize">
                                             {item}
                                             <span className="text-red-500">
                                                *
                                             </span>
                                          </label>
                                          <input
                                             type="text"
                                             name={item}
                                             value={detailedFields[item] || ""}
                                             onChange={handleInputChange}
                                             className="w-full p-2 border rounded"
                                             placeholder={`Enter your ${item} here...`}
                                          />
                                       </div>
                                       <div className="flex items-center ml-4">
                                          <label className="block mb-1 mr-2">
                                             Quantity
                                             <span className="text-red-500">
                                                *
                                             </span>
                                          </label>
                                          <input
                                             type="number"
                                             name={`${item}Qty`}
                                             required={
                                                detailedFields[item] !== ""
                                             }
                                             value={
                                                detailedFields[`${item}Qty`] ||
                                                0
                                             }
                                             onChange={handleInputChange}
                                             className="w-16 text-center border px-2 py-1"
                                             min="0"
                                          />
                                       </div>
                                    </div>
                                 ))}
                              </div>
                           </>
                        ) : formData.category === "Front Desk" ? (
                           <>
                              <div className="py-4 mx-auto">
                              <h2 className="text-xl font-bold mb-4">
                                 Key Cards
                              </h2>

                              {["Mag Stripe", "RFID", "ULC"].map(
                                 (tab) => (
                                    <div
                                       key={tab}
                                       className="flex justify-between items-center mb-4"
                                    >
                                       <div className="">
                                          <label className="block mb-1">
                                             {tab}
                                          </label>
                                       </div>
                                       <div className="flex items-center">
                                          <label className="block mb-1 mr-2">
                                             Quantity
                                             <span className="text-red-500">
                                                *
                                             </span>
                                          </label>
                                          <input
                                             type="number"
                                             name={"Key cards "+tab+" Qty"}
                                             value={detailedFields["Key cards "+tab+" Qty"] || 0}
                                             onChange={handleInputChange}
                                             className="w-16 text-center border px-2 py-1"
                                             min="0"
                                          />
                                       </div>
                                    </div>
                                 )
                              )}
                                 {/* <CheckboxGroup
                                    label="Key Cards"
                                    name="keyCards"
                                    options={[
                                       {
                                          label: "Mag Stripe",
                                          value: "magStripe",
                                          checked:
                                             detailedFields.keyCards
                                                ?.magStripe || false,
                                       },
                                       {
                                          label: "RFID",
                                          value: "rfid",
                                          checked:
                                             detailedFields.keyCards?.rfid ||
                                             false,
                                       },
                                       {
                                          label: "ULC",
                                          value: "ulc",
                                          checked:
                                             detailedFields.keyCards?.ulc ||
                                             false,
                                       },
                                    ]}
                                    handleChange={handleCheckboxChange}
                                 />

                                 <div className="flex items-center mb-4">
                                    <label className="block mr-2">
                                       Quantity
                                       <span className="text-red-500">*</span>
                                    </label>
                                    <input
                                       type="number"
                                       name="keyCardsQty"
                                       value={detailedFields.keyCardsQty || 0}
                                       onChange={handleInputChange}
                                       className="w-16 text-center border px-2 py-1"
                                       min="0"
                                    />
                                 </div> */}
                                 <h2 className="text-xl font-bold mb-4">
                                 Key Fob
                              </h2>

                              {["Mag Stripe", "RFID", "ULC"].map(
                                 (tab) => (
                                    <div
                                       key={tab}
                                       className="flex items-center mb-4"
                                    >
                                       <div className="flex-grow">
                                          <label className="block mb-1">
                                             {tab}
                                          </label>
                                       </div>
                                       <div className="flex items-center">
                                          <label className="block mb-1 mr-2">
                                             Quantity
                                             <span className="text-red-500">
                                                *
                                             </span>
                                          </label>
                                          <input
                                             type="number"
                                             name={"Key Fob "+tab+" Qty"}
                                             value={detailedFields["Key Fob "+tab+" Qty"] || 0}
                                             onChange={handleInputChange}
                                             className="w-16 text-center border px-2 py-1"
                                             min="0"
                                          />
                                       </div>
                                    </div>
                                 )
                              )}

                                 <div className="mb-4">
                                    <label className="block mb-1">
                                       Brand Supplies
                                       <span className="text-red-500">*</span>
                                    </label>
                                    <input
                                       type="text"
                                       name="brandSupplies"
                                       value={
                                          detailedFields.brandSupplies || ""
                                       }
                                       onChange={handleInputChange}
                                       className="w-full p-2 border rounded"
                                       placeholder="Enter your text here..."
                                    />
                                 </div>
                                 <div className="flex items-center">
                                    <label className="block mr-2">
                                       Quantity
                                       <span className="text-red-500">*</span>
                                    </label>
                                    <input
                                       type="number"
                                       name="brandSuppliesQty"
                                       value={
                                          detailedFields.brandSuppliesQty || 0
                                       }
                                       onChange={handleInputChange}
                                       className="w-16 text-center border px-2 py-1"
                                       min="0"
                                    />
                                 </div>
                              </div>
                           </>
                        ) : formData.category === "Housekeeping" ? (
                           <div className="p-4 mx-auto">
                              {[
                                 "Vacuums",
                                 "Vacuum Filters",
                                 "Sharps Containers",
                                 "Clean Cloth/Rag bags",
                                 "Dirty Cloth/Rag bags",
                                 "MPOR Stamps",
                                 "Miscellaneous",
                              ].map((item, index) => (
                                 <div
                                    key={item}
                                    className="flex items-center mb-4"
                                 >
                                    <div className="flex-grow">
                                       <label className="block mb-1 capitalize">
                                          {item}
                                       </label>
                                       <input
                                          type="text"
                                          name={item}
                                          value={detailedFields[item] || ""}
                                          onChange={handleInputChange}
                                          className="w-full p-2 border rounded"
                                          placeholder={`Enter items related to ${item} here...`}
                                       />
                                    </div>
                                    <div className="flex items-center ml-4">
                                       <label className="block mb-1 mr-2">
                                          Quantity
                                          <span className="text-red-500">
                                             *
                                          </span>
                                       </label>
                                       <input
                                          type="number"
                                          name={`${item}Qty`}
                                          value={
                                             detailedFields[`${item}Qty`] || 0
                                          }
                                          onChange={handleInputChange}
                                          className="w-16 text-center border px-2 py-1"
                                          min="0"
                                       />
                                    </div>
                                 </div>
                              ))}
                           </div>
                        ) : formData.category === "Maintenance" ? (
                           <div className="p-4 mx-auto">
                              {[
                                 "Miscellaneous Purchases",
                                 "Refrigenerators",
                                 "Microwaves",
                                 "King Bed Frame",
                                 "Queen Bed  Frame",
                              ].map((item, index) => (
                                 <div
                                    key={item}
                                    className="flex items-center mb-4"
                                 >
                                    <div className="flex-grow">
                                       <label className="block mb-1 capitalize">
                                          {item}
                                       </label>
                                       <input
                                          type="text"
                                          name={item}
                                          value={detailedFields[item] || ""}
                                          onChange={handleInputChange}
                                          className="w-full p-2 border rounded"
                                          placeholder={`Enter items related to ${item} here...`}
                                       />
                                    </div>
                                    <div className="flex items-center ml-4">
                                       <label className="block mb-1 mr-2">
                                          Quantity
                                          <span className="text-red-500">
                                             *
                                          </span>
                                       </label>
                                       <input
                                          type="number"
                                          name={`${item}Qty`}
                                          value={
                                             detailedFields[`${item}Qty`] || 0
                                          }
                                          onChange={handleInputChange}
                                          className="w-16 text-center border px-2 py-1"
                                          min="0"
                                       />
                                    </div>
                                 </div>
                              ))}
                              <h2 className="text-xl font-bold mb-4">
                                 Water Softener Salt
                              </h2>

                              {["55 Bags Green", "55 Bags Yellow"].map(
                                 (tab) => (
                                    <div
                                       key={tab}
                                       className="flex items-center mb-4"
                                    >
                                       <div className="flex-grow">
                                          <label className="block mb-1">
                                             {tab}
                                          </label>
                                       </div>
                                       <div className="flex items-center">
                                          <label className="block mb-1 mr-2">
                                             Quantity
                                             <span className="text-red-500">
                                                *
                                             </span>
                                          </label>
                                          <input
                                             type="number"
                                             name={tab}
                                             value={detailedFields[tab] || 0}
                                             onChange={handleInputChange}
                                             className="w-16 text-center border px-2 py-1"
                                             min="0"
                                          />
                                       </div>
                                    </div>
                                 )
                              )}
                           </div>
                        ) : formData.category ===
                          "License Re-newel Fees/Fines" ? (
                           <div className="p-4 mx-auto">
                              {[
                                 "Business",
                                 "Elevator",
                                 "Food",
                                 "Room Rental",
                                 "Retail",
                                 "Pool",
                              ].map((item, index) => (
                                 <div
                                    key={item}
                                    className="flex items-center mb-4"
                                 >
                                    <div className="flex-grow">
                                       <label className="block mb-1 capitalize">
                                          {item}
                                       </label>
                                       <input
                                          type="text"
                                          name={item}
                                          value={detailedFields[item] || ""}
                                          onChange={handleInputChange}
                                          className="w-full p-2 border rounded"
                                          placeholder={`Enter items related to ${item} here...`}
                                       />
                                    </div>
                                    <div className="flex items-center ml-4">
                                       <label className="block mb-1 mr-2">
                                          Quantity
                                          <span className="text-red-500">
                                             *
                                          </span>
                                       </label>
                                       <input
                                          type="number"
                                          name={`${item}Qty`}
                                          value={
                                             detailedFields[`${item}Qty`] || 0
                                          }
                                          onChange={handleInputChange}
                                          className="w-16 text-center border px-2 py-1"
                                          min="0"
                                       />
                                    </div>
                                 </div>
                              ))}
                           </div>
                        ) : (
                           <></>
                        )}

                        <div>
                           <label className="block mb-1">
                              Explain Your Issues
                              <span className="text-red-500">*</span>
                           </label>
                           <textarea
                              name="issues"
                              value={formData.issues}
                              onChange={handleChange}
                              className="w-full p-2 border rounded"
                              rows="4"
                              required
                           />
                        </div>
                        <div>
                           <label className="block mb-1">Comment</label>
                           <textarea
                              name="commentText"
                              value={formData.commentText}
                              onChange={handleChange}
                              className="w-full p-2 border rounded"
                              rows="4"
                           />
                        </div>
                        <div>
                           <label className="block mb-1">
                              Upload / Attachment
                           </label>
                           <input
                              type="file"
                              name="attachment"
                              multiple
                              onChange={handleChange}
                              className="w-full p-2 border rounded"
                           />
                        </div>
                        <div className="text-center">
                           <button
                              type="submit"
                              className="px-4 py-2 bg-primary hover:bg-secondry font-semibold text-white rounded"
                           >
                              Submit
                           </button>
                        </div>
                     </form>
                  </div>
               </main>
            </div>
         </div>
      </>
   );
};

CreateNewTicket.propTypes = {
   hotelLoading: PropTypes.bool.isRequired,
   depLoading: PropTypes.bool.isRequired,
   hotels: PropTypes.array,
   departments: PropTypes.array,
   loadHotels: PropTypes.func.isRequired,
   loadDepartments: PropTypes.func.isRequired,
   addTicket: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
   hotelLoading: state.hotels.loading,
   depLoading: state.departments.loading,
   departments: state.departments.departments,
   hotels: state.hotels.hotels,
});

export default connect(mapStateToProps, {
   loadHotels,
   loadDepartments,
   addTicket,
})(CreateNewTicket);

// const CheckboxGroup = ({ label, name, options, handleChange }) => (
//    <div className="mb-4 w-full">
//       <label className="block mb-1">
//          {label}
//          <span className="text-red-500">*</span>
//       </label>
//       <div className="flex w-full justify-around flex-wrap items-center space-x-4">
//          {options.map((option) => (
//             <div key={option.value} className="flex items-center">
//                <input
//                   type="checkbox"
//                   name={`${name}.${option.value}`}
//                   checked={option.checked}
//                   onChange={handleChange}
//                   className="mr-2"
//                />
//                <label className="text-sm">{option.label}</label>
//             </div>
//          ))}
//       </div>
//    </div>
// );
