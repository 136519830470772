import React, { useState } from "react";
import { FaUserCircle } from "react-icons/fa";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import amerilodgeLogo from "../assets/amerilodge logo.png";
import { URLImage } from "../utils/URL";
import { logout } from "../redux/tasks/authT";

const TopBar = ({ user, logout }) => {
   const navigate = useNavigate();
   const [menuOpen, setMenuOpen] = useState(false);

   const toggleMenu = () => {
      setMenuOpen(!menuOpen);
   };

   return (
      <header className="bg-white shadow-md p-4 flex justify-between items-center">
         <div className="flex items-center">
            <img
               src={amerilodgeLogo}
               alt="Amerilodge Group Logo"
               className="h-20"
            />
         </div>
         <div className="relative">
            <div
               className="flex items-center cursor-pointer"
               onClick={toggleMenu}
            >
               <div className="mr-6">
                  {user?.img ? (
                     <img
                        src={
                           user.img.startsWith(".")
                              ? URLImage + user.img
                              : user.img
                        }
                        alt="User Icon"
                        className="h-7 rounded-full"
                     />
                  ) : (
                     <FaUserCircle size={30} />
                  )}
               </div>
               <span className="text-2xl mr-4">
                  {user?.firstName ? user.firstName : "Name"}
               </span>
               <svg
                  className={`ml-2 h-4 w-4 transition-transform transform ${
                     menuOpen ? "rotate-180" : ""
                  }`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
               >
                  <path
                     strokeLinecap="round"
                     strokeLinejoin="round"
                     strokeWidth="2"
                     d="M19 9l-7 7-7-7"
                  ></path>
               </svg>
            </div>
            {menuOpen && (
               <div className="absolute right-0 mt-2 w-48 z-20 bg-white border border-gray-200 rounded-md shadow-lg">
                  <div className="py-2">
                     {user.role !== "ENDUSER" ? (
                        <button
                           onClick={() => navigate("/user-settings")}
                           className="block w-full text-start px-4 py-2 text-gray-800 hover:bg-gray-200"
                        >
                           Profile Settings
                        </button>
                     ) : (
                        <></>
                     )}
                     <button
                        onClick={() => logout()}
                        className="block w-full text-start px-4 py-2 text-gray-800 hover:bg-gray-200"
                     >
                        Log Out
                     </button>
                  </div>
               </div>
            )}
         </div>
      </header>
   );
};

TopBar.propTypes = {
   user: PropTypes.object,
   logout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
   user: state.auth.user,
});

export default connect(mapStateToProps, { logout })(TopBar);
