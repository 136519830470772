import axios from "axios";

import { URL } from "../../utils/URL";
import setAuthToken from "../../utils/setAuthToken";
import {
   LOGIN_SUCCESS,
   LOGIN_FAIL,
   SELF_CHANGE,
   LOGOUT,
   GET_SELF,
   SELF_ERROR,
} from "./types";

export const login = (setLoad, toast, payload) => async (dispatch) => {
   const { email, password } = payload;

   try {
      const body = JSON.stringify({ email, password });
      const config = {
         headers: {
            "Content-Type": "Application/json",
         },
      };
      const response = await axios.post(URL + "/api/auth/", body, config);
      dispatch({
         type: LOGIN_SUCCESS,
         payload: response.data,
      });
      toast.success("Login Successful", {
         autoClose: 5000,
      });
   } catch (e) {
      setLoad(false);
      if (e.response) {
         toast.error(e.response.data.message, {
            autoClose: 5000,
         });
         dispatch({
            type: LOGIN_FAIL,
            payload: e.response.data,
         });
      } else {
         toast.error(e.message, {
            autoClose: 5000,
         });
      }
   }
   if (localStorage.token) {
      setAuthToken(localStorage.token);
   }
};

export const getSelf = (toast, navigate, token) => async (dispatch) => {
   try {
      const response = await axios.get(URL + "/api/auth/");
      response.data.token = token;
      dispatch({
         type: GET_SELF,
         payload: response.data,
      });
      toast.success("Login Successful", {
         autoClose: 5000,
      });
      navigate("/user-dashboard");
   } catch (e) {
      toast.error(e.response.data.message, {
         autoClose: 5000,
      });
      navigate("/");
      dispatch({
         type: LOGIN_FAIL,
         payload: e.response.data,
      });
   }
   // if (localStorage.token) {
   //    setAuthToken(localStorage.token);
   // }
};

export const editSelf =
   (toast, setLoad, fromdata, navigate) => async (dispatch) => {
      try {
         const config = {
            headers: {
               "Content-Type": "nultipart/formdata",
            },
         };
         const response = await axios.put(
            URL + "/api/users/",
            fromdata,
            config
         );
         dispatch({ type: SELF_CHANGE, payload: response.data });
         toast.success("user updated", {
            autoClose: 5000,
         });
         setLoad(false);
         navigate(-1);
      } catch (error) {
         toast.error(error.response.data.message, {
            autoClose: 5000,
         });
         setLoad(false);
         dispatch({
            type: SELF_ERROR,
            payload: {
               msg: error.response.data.message,
               status: error.response.status,
            },
         });
      }
   };

export const logout = () => (dispatch) => {
   localStorage.removeItem("token");
   setAuthToken(null);
   dispatch({
      type: LOGOUT,
   });
   // navigate("/");
};
