import {
   LOGIN_SUCCESS,
   LOGIN_FAIL,
   SELF_CHANGE,
   GET_SELF,
   LOGOUT,
   SELF_ERROR,
} from "../tasks/types";
import setAuthToken from "../../utils/setAuthToken";

const initialState = {
   token: "",
   loading: true,
   isAuth: false,
   user: null,
   error: null,
};

export default function authR(state = initialState, action) {
   const { type, payload } = action;
   switch (type) {
      case LOGIN_SUCCESS:
         localStorage.setItem("token", payload.token);
         return {
            ...state,
            isAuth: true,
            loading: false,
            token: payload.token,
            user: payload.user,
            error: null,
         };
      case SELF_CHANGE:
         return {
            ...state,
            loading: false,
            user: payload.user,
         };
      case GET_SELF:
         return {
            ...state,
            loading: false,
            isAuth: true,
            token: payload.token,
            user: payload.user,
         };
      case SELF_ERROR:
         return {
            ...state,
            loading: false,
            error: payload,
         };
      case LOGIN_FAIL:
         localStorage.removeItem("token");
         setAuthToken(null);
         return {
            ...state,
            loading: false,
            error: payload,
         };
      case LOGOUT:
         return initialState;
      default:
         return state;
   }
}
