import {
   REGIONS,
   REGIONS_ERROR,
   REGIONS_INIT_STATE,
   LOGOUT,
} from "../tasks/types";

const initialState = {
   loading: true,
   regions: null,
   error: null,
};

export default function authR(state = initialState, action) {
   const { type, payload } = action;
   switch (type) {
      case REGIONS:
         return {
            ...state,
            loading: false,
            regions: payload.regions,
         };
      case REGIONS_ERROR:
         return {
            ...state,
            loading: false,
            error: payload,
         };
      case REGIONS_INIT_STATE:
         return initialState;
      case LOGOUT:
         return initialState;
      default:
         return state;
   }
}
