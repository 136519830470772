import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { changeUser, deleteUser } from "../../redux/tasks/usersT";
import TopBar from "../../components/TopBar";
import Sidebar from "../../components/Sidebar";
import Spinner from "../../components/Spinner";

const EmployeeDetails = ({ changeUser, deleteUser }) => {
   const location = useLocation();
   const { emp } = location.state;
   const navigate = useNavigate();
   const [load, setLoad] = useState(false);
   const [data, setData] = useState({
      role: "",
      status: "",
   });

   useEffect(() => {
      if (emp) {
         setData({
            role: emp?.role,
            status: emp?.status,
         });
      }
   }, [emp]);

   const handleChange = (e) => {
      const { name, value } = e.target;
      setData({ ...data, [name]: value });
   };

   const handleSubmit = (e) => {
      e.preventDefault();
      setLoad(true);
      changeUser({ toast, setLoad, id: emp._id, data, navigate });
   };

   const handleDelete = (e) => {
      e.preventDefault();
      deleteUser({ toast, setLoad, id: emp._id, navigate });
   };

   return load ? (
      <Spinner />
   ) : (
      <div className="flex flex-col h-screen">
         <TopBar />
         <div className="flex flex-1 overflow-hidden">
            <Sidebar />
            <main className="flex-1 p-4 overflow-y-auto">
               <div className="flex flex-col h-full items-center">
                  <h1 className="text-3xl font-bold my-10 text-center">
                     Employee Details
                  </h1>
                  <div className="w-full max-w-2xl flex  flex-col gap-y-3">
                     <div className="grid grid-cols-2 gap-4 mb-4">
                        <label className="font-semibold">Name</label>
                        <span>{emp.firstName}</span>
                     </div>
                     <div className="grid grid-cols-2 gap-4 mb-4">
                        <label className="font-semibold">Last Name</label>
                        <span>{emp.lastName}</span>
                     </div>
                     <div className="grid grid-cols-2 gap-4 mb-4">
                        <label className="font-semibold">Email</label>
                        <span>{emp.email}</span>
                     </div>
                     <div className="grid grid-cols-2 gap-4 mb-4">
                        <label className="font-semibold">Phone</label>
                        <span>{emp.phone}</span>
                     </div>
                     <div className="grid grid-cols-2 gap-4 mb-4">
                        <label className="font-semibold">Role</label>
                        <select
                           onChange={handleChange}
                           name="role"
                           value={data.role}
                           className="w-full p-2 border rounded"
                        >
                           <option>Select Here....</option>
                           <option value={"DEPHEAD"}>Department Head</option>
                           <option value={"EMPLOYEE"}>Employee</option>
                        </select>
                     </div>
                     <div className="grid grid-cols-2 gap-4 mb-4">
                        <label className="font-semibold">Status</label>
                        <select
                           required
                           onChange={handleChange}
                           name="status"
                           value={data.status}
                           className="w-full p-2 border rounded"
                        >
                           <option>Select Here....</option>
                           <option value={"ACTIVE"}>Active</option>
                           <option value={"BLOCKED"}>Blocked</option>
                        </select>
                     </div>
                     <div className="flex gap-y-3 justify-around items-center mt-4">
                        <button
                           onClick={(e) => handleSubmit(e)}
                           className="bg-primary w-1/4 hover:bg-neutral-500 font-semibold text-white py-2 px-4 rounded"
                        >
                           Update
                        </button>
                        <button
                           onClick={(e) => navigate(-1)}
                           className="bg-neutral-700 w-1/4 hover:bg-neutral-500 font-semibold text-white py-2 px-4 rounded"
                        >
                           Cancel
                        </button>
                        <button
                           onClick={(e) => handleDelete(e)}
                           className="bg-sysRed w-1/4 hover:bg-neutral-500 font-semibold text-white py-2 px-4 rounded"
                        >
                           Delete User
                        </button>
                     </div>
                  </div>
               </div>
            </main>
         </div>
      </div>
   );
};

EmployeeDetails.propTypes = {
   changeUser: PropTypes.func.isRequired,
   deleteUser: PropTypes.func.isRequired,
};

export default connect(null, { changeUser, deleteUser })(EmployeeDetails);
