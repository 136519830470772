import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";

import TopBar from "../../components/TopBar";
import Sidebar from "../../components/Sidebar";
import Spinner from "../../components/Spinner";
import { loadUsersFromMyDep } from "../../redux/tasks/usersT";
import { loadHotels, changeHotelAAUser } from "../../redux/tasks/hotelsT";
import { loadMyDep, changeAAUsers } from "../../redux/tasks/departmentsT";

const Settings = ({
   loading,
   userLoading,
   department,
   users,
   me,
   hotels,
   loadMyDep,
   loadUsersFromMyDep,
   changeAAUsers,
   loadHotels,
   changeHotelAAUser,
}) => {
   const navigate = useNavigate();
   const [load, setLoad] = useState(false);
   const [categories, setCategories] = useState([]);
   const [hotelData, setHotelData] = useState({
      hotelID: "",
      userID: "",
      cat: "service",
   });

   useEffect(() => {
      loadMyDep();
      loadUsersFromMyDep();
      loadHotels();
   }, [loadMyDep, loadUsersFromMyDep, loadHotels, load]);

   useEffect(() => {
      if (!loading) {
         const newCategories = department?.categories?.map((category) => ({
            catName: category.categoryName,
            userId: category.AAUser?._id,
         }));
         setCategories(newCategories);
      }
   }, [loading, department]);

   const handleChange = (e, index) => {
      const { name, value } = e.target;
      const myCats = [...categories];
      myCats[index] = { catName: name, userId: value };
      setCategories(myCats);
   };

   // const handleHotelChange = (e, hotelID) => {
   //    const { name, value } = e.target;
   //    setHotelData({ ...hotelData, [name]: value, hotelID: hotelID });
   // };

   const handleHotelChange = (e) => {
      const { name, value } = e.target;
      let newData = { ...hotelData, [name]: value };
      if (name === "hotelID") {
         let hotel = hotels.find((h) => h._id === value);
         if (hotel.AAUserR_M_service) {
            newData = {
               ...newData,
               userID: hotel.AAUserR_M_service._id,
            };
         }
      }
      setHotelData(newData);
   };

   const handleSubmit = (e) => {
      e.preventDefault();
      setLoad(true);
      changeAAUsers(toast, setLoad, department._id, categories, navigate);
   };

   const handleHotelSubmit = (e) => {
      e.preventDefault();
      setLoad(true);
      changeHotelAAUser(toast, setLoad, hotelData, navigate);
      setHotelData({ ...hotelData, hotelID: "", userID: "" });
   };

   return loading || userLoading || load ? (
      <Spinner />
   ) : (
      <>
         <div className="flex flex-col h-screen">
            <TopBar />
            <div className="flex flex-1 overflow-hidden">
               <Sidebar />
               <main className="flex-1 p-4 overflow-y-auto">
                  <div className="flex flex-col h-full items-center">
                     <div className="w-full max-w-3xl">
                        {me.department !== "Repair & Maintenance" && (
                           <>
                              <h1 className="text-3xl font-bold mt-10 mb-16 text-center">
                                 Auto Assign Users to your department's
                                 categories
                              </h1>
                              {categories.length > 0 &&
                                 categories.map((cat, index) => (
                                    <div
                                       className="flex flex-col md:flex-row justify-between my-5"
                                       key={index}
                                    >
                                       <label className="font-semibold mb-2 md:mb-0">
                                          Automatically Assign {cat.catName} To:
                                       </label>
                                       <select
                                          name={cat.catName}
                                          value={cat.userId}
                                          onChange={(e) =>
                                             handleChange(e, index)
                                          }
                                          className="w-full md:w-1/2 p-2 border rounded"
                                       >
                                          <option value=""> Select User</option>
                                          {users?.map((u) => (
                                             <option key={u._id} value={u._id}>
                                                {u.firstName + " " + u.lastName}
                                             </option>
                                          ))}
                                       </select>
                                    </div>
                                 ))}
                              <div className="flex justify-around mt-16">
                                 <button
                                    onClick={(e) => navigate(-1)}
                                    className="bg-sysRed hover:bg-neutral-600 w-1/3 font-semibold text-white py-2 px-4 rounded"
                                 >
                                    Cancel
                                 </button>
                                 <button
                                    onClick={(e) => handleSubmit(e)}
                                    className="bg-primary hover:bg-neutral-600 w-1/3 font-semibold text-white py-2 px-4 rounded"
                                 >
                                    Update
                                 </button>
                              </div>
                           </>
                        )}
                        {me.department === "Repair & Maintenance" ? (
                           <>
                              <h1 className="text-3xl font-bold mt-10 mb-16 text-center">
                                 Auto Assign hotel tickets to a Users
                              </h1>
                              {/* {hotels?.length > 0 &&
                                 hotels.map((hotel, index) => (
                                    <div key={index}>
                                       {hotel.AAUserR_M_service ? (
                                          <div className="flex flex-col md:flex-row justify-between my-5">
                                             <label className="font-semibold mb-2 md:mb-0">
                                                Automatically Assign{" "}
                                                {hotel.name} To:
                                             </label>
                                             <select
                                                name="userID"
                                                value={hotelData.userID}
                                                onChange={(e) =>
                                                   handleHotelChange(
                                                      e,
                                                      hotel._id
                                                   )
                                                }
                                                className="w-full md:w-1/2 p-2 border rounded"
                                             >
                                                <option value="">
                                                   Select User
                                                </option>
                                                {users?.map((u) => (
                                                   <option
                                                      key={u._id}
                                                      value={u._id}
                                                   >
                                                      {u.firstName +
                                                         " " +
                                                         u.lastName}
                                                   </option>
                                                ))}
                                             </select>
                                          </div>
                                       ) : (
                                          <></>
                                       )}
                                    </div>
                                 ))} */}
                              <div className="flex flex-col md:flex-row justify-between my-5">
                                 <label className="font-semibold mb-2 md:mb-0">
                                    Hotel Name:
                                 </label>
                                 <select
                                    name="hotelID"
                                    value={hotelData.hotelID}
                                    onChange={(e) => handleHotelChange(e)}
                                    className="w-full md:w-1/2 p-2 border rounded"
                                 >
                                    <option value="">Select Hotel</option>
                                    {hotels?.map((h) => (
                                       <option key={h._id} value={h._id}>
                                          {h.name}
                                       </option>
                                    ))}
                                 </select>
                              </div>
                              {hotelData.hotelID !== "" && (
                                 <div className="flex flex-col md:flex-row justify-between my-5">
                                    <label className="font-semibold mb-2 md:mb-0">
                                       User Name:
                                    </label>
                                    <select
                                       name="userID"
                                       value={hotelData.userID}
                                       onChange={(e) => handleHotelChange(e)}
                                       className="w-full md:w-1/2 p-2 border rounded"
                                    >
                                       <option value="">Select User</option>
                                       {users?.map((u) => (
                                          <option key={u._id} value={u._id}>
                                             {u.firstName + " " + u.lastName}
                                          </option>
                                       ))}
                                    </select>
                                 </div>
                              )}
                              <div className="flex justify-around mt-16">
                                 <button
                                    onClick={(e) => navigate(-1)}
                                    className="bg-sysRed hover:bg-neutral-600 w-1/3 font-semibold text-white py-2 px-4 rounded"
                                 >
                                    Cancel
                                 </button>
                                 <button
                                    onClick={(e) => handleHotelSubmit(e)}
                                    className="bg-primary hover:bg-neutral-600 w-1/3 font-semibold text-white py-2 px-4 rounded"
                                 >
                                    Update
                                 </button>
                              </div>
                           </>
                        ) : (
                           <></>
                        )}
                     </div>
                  </div>
               </main>
            </div>
         </div>
      </>
   );
};

Settings.propTypes = {
   loading: PropTypes.bool,
   userLoading: PropTypes.bool.isRequired,
   department: PropTypes.object,
   users: PropTypes.array,
   me: PropTypes.object,
   hotels: PropTypes.array,
   loadMyDep: PropTypes.func.isRequired,
   loadUsersFromMyDep: PropTypes.func.isRequired,
   changeAAUsers: PropTypes.func.isRequired,
   loadHotels: PropTypes.func.isRequired,
   changeHotelAAUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
   loading: state.departments.singleDepartmentLoadiing,
   department: state.departments.singleDepartment,
   userLoading: state.users.loading,
   users: state.users.users,
   me: state.auth.user,
   hotels: state.hotels.hotels,
});

export default connect(mapStateToProps, {
   loadMyDep,
   loadUsersFromMyDep,
   changeAAUsers,
   loadHotels,
   changeHotelAAUser,
})(Settings);
