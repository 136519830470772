import React, { useState, useEffect } from "react";
import {
   LineChart,
   Line,
   XAxis,
   YAxis,
   CartesianGrid,
   Tooltip,
   Legend,
   BarChart,
   Bar,
} from "recharts";
import { format, parseISO } from "date-fns";

// Hook to get window size
const useWindowSize = () => {
   const [size, setSize] = useState([window.innerWidth, window.innerHeight]);

   useEffect(() => {
      const handleResize = () => {
         setSize([window.innerWidth, window.innerHeight]);
      };
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
   }, []);

   return size;
};

const ParamGraph = ({ tickets, role }) => {
   const [filteredTickets, setFilteredTickets] = useState([]);
   const [selectedDepartment, setSelectedDepartment] = useState("");
   const [selectedEmployee, setSelectedEmployee] = useState("");
   const [selectedCategory, setSelectedCategory] = useState("");
   const [selectedStatus, setSelectedStatus] = useState("");
   const [selectedTimePeriod, setSelectedTimePeriod] = useState("");
   const [windowWidth, windowHeight] = useWindowSize();

   useEffect(() => {
      filterTickets();
      //eslint-disable-next-line
   }, [
      selectedDepartment,
      selectedEmployee,
      selectedCategory,
      selectedStatus,
      selectedTimePeriod,
      tickets,
   ]);

   const filterTickets = () => {
      let filtered = tickets;

      if (selectedDepartment) {
         filtered = filtered.filter(
            (ticket) => ticket.department === selectedDepartment
         );
      }

      if (selectedEmployee) {
         filtered = filtered.filter(
            (ticket) =>
               ticket.assignedTo &&
               `${ticket.assignedTo.firstName} ${ticket.assignedTo.lastName}` ===
                  selectedEmployee
         );
      }

      if (selectedCategory) {
         filtered = filtered.filter(
            (ticket) => ticket.category === selectedCategory
         );
      }

      if (selectedStatus) {
         filtered = filtered.filter(
            (ticket) => ticket.status === selectedStatus
         );
      }

      if (selectedTimePeriod) {
         const now = new Date();
         let startDate;

         if (selectedTimePeriod === "2 Months") {
            startDate = new Date(now.setMonth(now.getMonth() - 2));
         } else if (selectedTimePeriod === "3 Months") {
            startDate = new Date(now.setMonth(now.getMonth() - 3));
         }

         filtered = filtered.filter(
            (ticket) => new Date(ticket.createdAt) >= startDate
         );
      }

      // Aggregate data by date
      const aggregatedData = aggregateData(filtered);

      setFilteredTickets(aggregatedData);
   };

   const aggregateData = (tickets) => {
      const dataMap = tickets.reduce((acc, ticket) => {
         const date = format(parseISO(ticket.createdAt), "yyyy-MM-dd");
         if (!acc[date]) {
            acc[date] = { date, count: 0 };
         }
         acc[date].count += 1;
         return acc;
      }, {});

      return Object.values(dataMap);
   };

   const getUniqueValues = (key) => {
      return [
         ...new Set(
            tickets.map((ticket) => {
               if (key === "assignedTo") {
                  return ticket[key]
                     ? `${ticket[key].firstName} ${ticket[key].lastName}`
                     : "Un-Assigned";
               }
               return ticket[key];
            })
         ),
      ];
   };

   return (
      <div className="p-4">
         <div className="flex flex-col md:flex-row md:justify-between mb-4">
            {role === "ADMIN" ? (
               <>
                  <div className="flex flex-wrap justify-center gap-y-2 md:justify-start space-x-2 mb-2 md:mb-0">
                     <select
                        onChange={(e) => setSelectedDepartment(e.target.value)}
                        className="p-2 bg-gray-200 w-full md:w-auto"
                     >
                        <option value="">Department</option>
                        {getUniqueValues("department").map((dept) => (
                           <option key={dept} value={dept}>
                              {dept}
                           </option>
                        ))}
                     </select>
                     <select
                        onChange={(e) => setSelectedEmployee(e.target.value)}
                        className="p-2 bg-gray-200 w-full md:w-auto"
                     >
                        <option value="">Employee</option>
                        {getUniqueValues("assignedTo").map((emp) => (
                           <option key={emp} value={emp}>
                              {emp}
                           </option>
                        ))}
                     </select>
                     <select
                        onChange={(e) => setSelectedCategory(e.target.value)}
                        className="p-2 bg-gray-200 w-full md:w-auto"
                     >
                        <option value="">Category</option>
                        {getUniqueValues("category").map((cat) => (
                           <option key={cat} value={cat}>
                              {cat}
                           </option>
                        ))}
                     </select>
                     <select
                        onChange={(e) => setSelectedStatus(e.target.value)}
                        className="p-2 bg-gray-200 w-full md:w-auto"
                     >
                        <option value="">Status</option>
                        <option value="UNASSIGNED">Un-Assigned</option>
                        <option value="WORKINPROGRESS">Work in Progress</option>
                        <option value="MARKEDASRESOLVED">
                           Marked as Resolved
                        </option>
                        <option value="RESOLVED">Resolved</option>
                     </select>
                  </div>
                  <div className="flex justify-center md:justify-start">
                     <select
                        onChange={(e) => setSelectedTimePeriod(e.target.value)}
                        className="p-2 bg-gray-200 w-full md:w-auto"
                     >
                        <option value="">Time Period</option>
                        <option value="2 Months">2 Months</option>
                        <option value="3 Months">3 Months</option>
                     </select>
                  </div>
               </>
            ) : (
               <></>
            )}
         </div>

         <div className="grid grid-cols-1 gap-10">
            <BarChart
               width={Math.min(windowWidth * 0.9, 800)}
               height={Math.min(windowHeight * 0.3, 400)}
               data={filteredTickets}
            >
               <CartesianGrid strokeDasharray="3 3" />
               <XAxis
                  dataKey="date"
                  tickFormatter={(tick) =>
                     format(parseISO(tick), "MMM dd, yyyy")
                  }
               />
               <YAxis />
               <Tooltip
                  labelFormatter={(label) =>
                     format(parseISO(label), "MMM dd, yyyy")
                  }
               />
               <Legend />
               <Bar dataKey="count" fill="#8884d8" />
            </BarChart>

            <LineChart
               width={Math.min(windowWidth * 0.9, 800)}
               height={Math.min(windowHeight * 0.3, 400)}
               data={filteredTickets}
            >
               <CartesianGrid strokeDasharray="3 3" />
               <XAxis
                  dataKey="date"
                  tickFormatter={(tick) =>
                     format(parseISO(tick), "MMM dd, yyyy")
                  }
               />
               <YAxis />
               <Tooltip
                  labelFormatter={(label) =>
                     format(parseISO(label), "MMM dd, yyyy")
                  }
               />
               <Legend />
               <Line
                  type="monotone"
                  dataKey="count"
                  stroke="#8884d8"
                  activeDot={{ r: 8 }}
               />
            </LineChart>
         </div>
      </div>
   );
};

export default ParamGraph;
