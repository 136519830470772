import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { format } from "date-fns";

import { loadClosedTicketsDep } from "../../redux/tasks/ticketsT";
import TopBar from "../../components/TopBar";
import Sidebar from "../../components/Sidebar";
import Spinner from "../../components/Spinner";
import Table from "../../components/Table";

const TableColumns = [
   {
      Header: "#",
      accessor: "ticketNo",
   },
   {
      Header: "Subject",
      accessor: "subject",
   },
   {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => {
         return value === "WORKINPROGRESS"
            ? "Work in Progress"
            : value === "MARKEDASRESOLVED"
            ? "Marked as Resolved"
            : value === "RESOLVED"
            ? "Closed"
            : value === "ESCALATETOMANAGER"
            ? "Escalated to manager"
            : value === "ESCALATETODOO"
            ? "Escaalated to DOO"
            : value === "ESCALATETODEPHEAD"
            ? "Escaalated to department head"
            : "Un Assigned";
      },
   },
   {
      Header: "Created at",
      accessor: "createdAt",
      Cell: ({ value }) => {
         return format(new Date(value), "MM-dd-yyyy");
      },
   },
];

const TicketsClosed = ({ loading, tickets, loadClosedTicketsDep }) => {
   const navigate = useNavigate();
   const [numberOptions] = useState([5, 10, 15, 20, 25, 30]);
   const [globalFilter, setGlobalFilter] = useState("");

   useEffect(() => {
      loadClosedTicketsDep();
   }, [loadClosedTicketsDep]);

   const handleData = (index) => {
      const ticket = tickets[index];
      navigate("/single-ticket", { state: { ticket } });
   };

   return loading ? (
      <Spinner />
   ) : (
      <>
         <div className="flex flex-col h-screen">
            <TopBar />
            <div className="flex flex-1 overflow-hidden">
               <Sidebar />
               <main className="flex-1 p-4 overflow-y-auto">
                  <div className=" mx-auto">
                     <div className="flex flex-col gap-x-6 sm:flex-row sm:items-center sm:justify-end space-y-2 sm:space-y-0 sm:space-x-2 p-4">
                        <div className="flex items-center border border-gray-300 rounded sm:order-1">
                           <input
                              type="text"
                              placeholder="Search"
                              className="p-2 w-full border-none outline-none"
                              value={globalFilter}
                              onChange={(e) => setGlobalFilter(e.target.value)}
                           />
                           <div className="p-2">
                              <FaSearch className="text-gray-500" />
                           </div>
                        </div>
                     </div>
                     {tickets?.length > 0 ? (
                        <Table
                           data={tickets}
                           columns={TableColumns}
                           numberOptions={numberOptions}
                           dataFun={handleData}
                           globalFilter={globalFilter}
                           setGlobalFilter={setGlobalFilter}
                        />
                     ) : (
                        <div className="flex justify-center text-3xl font-semibold  text-neutral-600">
                           {" "}
                           No Tickets Found!
                        </div>
                     )}
                  </div>
               </main>
            </div>
         </div>
      </>
   );
};

TicketsClosed.propTypes = {
   loading: PropTypes.bool.isRequired,
   tickets: PropTypes.array,
   loadClosedTicketsDep: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
   loading: state.tickets.closedTicketsLoading,
   tickets: state.tickets.closedTickets,
});

export default connect(mapStateToProps, { loadClosedTicketsDep })(
   TicketsClosed
);
