import { thunk } from "redux-thunk";
import storage from "redux-persist/lib/storage";
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";
import { persistStore, persistReducer } from "redux-persist";

import rootReducer from "./reducers";

const initialState = {};

const middleWare = [thunk];

const persistConfig = {
   key: "root",
   storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(
   persistedReducer,
   initialState,
   composeWithDevTools(applyMiddleware(...middleWare))
);
const persistor = persistStore(store);

export { store, persistor };
