import { USERS, USERS_INIT_STATE, USERS_ERROR, LOGOUT } from "../tasks/types";

const initialState = {
   loading: true,
   users: null,
   error: null,
};

export default function authR(state = initialState, action) {
   const { type, payload } = action;
   switch (type) {
      case USERS:
         return {
            ...state,
            loading: false,
            users: payload.users,
         };
      case USERS_ERROR:
         return {
            ...state,
            loading: false,
            error: payload,
         };
      case USERS_INIT_STATE:
         return initialState;
      case LOGOUT:
         return initialState;
      default:
         return state;
   }
}
