import {
   TICKETS,
   TICKETS_CLOSED,
   TICKETS_OPEN,
   TICKETS_ESCALATED,
   TICKETS_ERROR,
   TICKETS_INIT_STATE,
   LOGOUT,
} from "../tasks/types";

const initialState = {
   tickets: null,
   loading: true,
   openTickets: null,
   openTicketsLoading: true,
   closedTickets: null,
   closedTicketsLoading: true,
   escalatedTickets: null,
   escalatedTicketsLoading: true,
   error: null,
};

export default function authR(state = initialState, action) {
   const { type, payload } = action;
   switch (type) {
      case TICKETS:
         return {
            ...state,
            loading: false,
            tickets: payload.tickets,
         };
      case TICKETS_OPEN:
         return {
            ...state,
            openTicketsLoading: false,
            openTickets: payload.tickets,
         };
      case TICKETS_CLOSED:
         return {
            ...state,
            closedTicketsLoading: false,
            closedTickets: payload.tickets,
         };
      case TICKETS_ESCALATED:
         return {
            ...state,
            escalatedTicketsLoading: false,
            escalatedTickets: payload.tickets,
         };
      case TICKETS_ERROR:
         return {
            ...state,
            loading: false,
            error: payload,
         };
      case TICKETS_INIT_STATE:
         return initialState;
      case LOGOUT:
         return initialState;
      default:
         return state;
   }
}
