import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

import TopBar from "../../components/TopBar";
import Sidebar from "../../components/Sidebar";
import { changeHotel, deleteHotel } from "../../redux/tasks/hotelsT";
import { loadRegions } from "../../redux/tasks/regionsT";
import Spinner from "../../components/Spinner";

const HotelDetails = ({ regions, loadRegions, changeHotel, deleteHotel }) => {
   const location = useLocation();
   const { hotel } = location.state;
   const navigate = useNavigate();
   const [load, setLoad] = useState(false);
   const [formData, setFormData] = useState({
      name: "",
      address: "",
      phone: "",
      innCode: "",
      regionId: "",
   });

   useEffect(() => {
      loadRegions();

      if (hotel) {
         setFormData({
            ...formData,
            name: hotel?.name,
            address: hotel?.address,
            phone: hotel?.phone,
            innCode: hotel?.innCode,
            regionId: hotel?.region?._id,
         });
      }
      // eslint-disable-next-line
   }, [hotel, loadRegions]);

   const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
   };

   const handleSubmit = (e) => {
      e.preventDefault();
      setLoad(true);
      changeHotel(toast, setLoad, hotel._id, formData, navigate);
   };

   const handleDelete = (e) => {
      e.preventDefault();
      setLoad(true);
      deleteHotel(toast, setLoad, hotel._id, navigate);
   };

   return load ? (
      <Spinner />
   ) : (
      <div className="flex flex-col h-screen">
         <TopBar />
         <div className="flex flex-1 overflow-hidden">
            <Sidebar />
            <main className="flex-1 p-4 overflow-y-auto">
               <div className="flex flex-col items-center justify-center">
                  <h1 className="text-3xl font-bold mb-6 text-center">
                     Hotel Details
                  </h1>
                  <form
                     onSubmit={handleSubmit}
                     className="w-full max-w-2xl space-y-6"
                  >
                     <div className="flex justify-between mb-4">
                        <label className="font-semibold">Name</label>
                        <span className="text-center w-1/2">
                           {formData.name}
                        </span>
                     </div>
                     <div className="flex justify-between mb-4">
                        <label className="font-semibold">Address</label>
                        <input
                           type="text"
                           name="address"
                           placeholder="Address Here"
                           value={formData.address}
                           onChange={handleChange}
                           className="w-1/2 text-center p-2 border rounded"
                        />
                     </div>
                     <div className="flex justify-between mb-4">
                        <label className="font-semibold">Phone Number</label>
                        <input
                           type="text"
                           name="phone"
                           placeholder="Phone Number Here"
                           value={formData.phone}
                           onChange={handleChange}
                           className="w-1/2 text-center p-2 border rounded"
                        />
                     </div>
                     <div className="flex justify-between mb-4">
                        <label className="font-semibold">INN Code</label>
                        <input
                           type="text"
                           name="innCode"
                           placeholder="Enter code here...."
                           value={formData.innCode}
                           onChange={handleChange}
                           className="w-1/2 text-center p-2 border rounded"
                        />
                     </div>
                     <div className="flex justify-between mb-4">
                        <label className="flex-1 mb-1 font-semibold">
                           Region
                        </label>
                        <select
                           name="regionId"
                           value={formData.regionId}
                           onChange={handleChange}
                           className="w-1/2 text-center p-2 border rounded"
                           required
                        >
                           <option value="">Select Here</option>
                           {regions?.map((d) => (
                              <option key={d._id} value={d._id}>
                                 {d.name}
                              </option>
                           ))}
                        </select>
                     </div>
                     <div className="flex justify-center mt-6 space-x-4">
                        <button
                           type="button"
                           onClick={(e) => navigate(-1)}
                           className="bg-neutral-700 w-1/3 hover:bg-neutral-500 font-semibold text-white py-2 px-4 rounded"
                        >
                           Cancel
                        </button>
                        <button
                           type="button"
                           onClick={(e) => handleDelete(e)}
                           className="bg-sysRed w-1/3 hover:bg-neutral-500 font-semibold text-white py-2 px-4 rounded"
                        >
                           Delete Hotel
                        </button>
                        <button
                           type="submit"
                           className="bg-primary  w-1/3 hover:bg-neutral-500 font-semibold text-white py-2 px-4 rounded"
                        >
                           Update Hotel
                        </button>
                     </div>
                  </form>
               </div>
            </main>
         </div>
      </div>
   );
};

HotelDetails.propTypes = {
   regions: PropTypes.array,
   changeHotel: PropTypes.func.isRequired,
   deleteHotel: PropTypes.func.isRequired,
   loadRegions: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
   regions: state.regions.regions,
});

export default connect(mapStateToProps, {
   changeHotel,
   deleteHotel,
   loadRegions,
})(HotelDetails);
