import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

import { editSelf } from "../redux/tasks/authT";
import TopBar from "../components/TopBar";
import Sidebar from "../components/Sidebar";
import Spinner from "../components/Spinner";
import { URLImage } from "../utils/URL";

const UserSettings = ({ user, editSelf }) => {
   const navigate = useNavigate();
   const fileInputRef = useRef(null);
   const [load, setLoad] = useState(false);
   const [conPass, setConPass] = useState("");
   const [formData, setFormData] = useState({
      phone: "",
      password: "",
      newPassword: "",
   });

   useEffect(() => {
      setFormData({ ...formData, phone: user?.phone });
      //eslint-disable-next-line
   }, [user]);

   const handleChange = (e) => {
      const { name, value, files } = e.target;
      if (name === "image" && files) {
         const file = files[0];
         const reader = new FileReader();
         reader.onloadend = () => {
            setFormData((prevFormData) => ({
               ...prevFormData,
               image: reader.result,
               imageFile: file,
            }));
         };
         reader.readAsDataURL(file);
      } else {
         setFormData({ ...formData, [name]: value });
      }
   };

   const handleImageClick = () => {
      fileInputRef.current.click();
   };

   const handleSubmit = (e) => {
      e.preventDefault();

      if (formData.newPassword === conPass) {
         // Prepare FormData
         const data = new FormData();
         Object.keys(formData).forEach((key) => {
            if (key === "imageFile" && formData[key]) {
               data.append("image", formData[key]);
            } else {
               data.append(key, formData[key]);
            }
         });

         setLoad(true);
         editSelf(toast, setLoad, data, navigate);
      } else {
         toast.error("Your Passwords do not match!");
      }
   };

   return load ? (
      <Spinner />
   ) : (
      <>
         <div className="flex flex-col h-screen">
            <TopBar />
            <div className="flex flex-1 overflow-hidden">
               <Sidebar />
               <main className="flex-1 p-4 overflow-y-auto">
                  <div className="flex flex-col items-center justify-center h-full">
                     <p className="flex w-1/2 text-xl mb-16 text-center">
                        If you also want to change the password then fill in the
                        new password and confirm password fields otherwise leave
                        them empty.
                     </p>
                     <form
                        onSubmit={handleSubmit}
                        className="w-full max-w-2xl space-y-4"
                     >
                        <div className=" flex rounded-full w-44 mx-auto relative">
                           <img
                              src={
                                 formData.image
                                    ? formData.image
                                    : user.img.startsWith(".")
                                    ? URLImage + user.img
                                    : user.img
                              }
                              alt="User"
                              className="w-44 h-44 rounded-full hover:cursor-pointer"
                              onClick={handleImageClick}
                           />

                           <input
                              type="file"
                              name="image"
                              accept="image/*"
                              className="hidden"
                              ref={fileInputRef}
                              onChange={handleChange}
                           />
                        </div>
                        <div className="flex w-full justify-around">
                           <label className=" mb-1 font-semibold">
                              First Name:
                           </label>
                           <label className=" mb-1">{user.firstName}</label>
                        </div>
                        <div className="flex w-full justify-around">
                           <label className=" mb-1 font-semibold">
                              Last Name:
                           </label>
                           <label className=" mb-1 font-semibold">
                              {user.lastName}
                           </label>
                        </div>
                        <div className="flex w-full justify-around">
                           <label className=" mb-1 font-semibold">Email:</label>
                           <label className=" mb-1 font-semibold">
                              {user.email}
                           </label>
                        </div>
                        <div>
                           <label className="block mb-1 font-semibold">
                              Contact No:
                              <span className="text-red-500">*</span>
                           </label>
                           <input
                              type="text"
                              name="phone"
                              value={formData.phone}
                              onChange={handleChange}
                              className="w-full p-2 border rounded"
                              placeholder="Enter your contact number"
                              required
                           />
                        </div>
                        <div>
                           <label className="block mb-1 font-semibold">
                              Current Password
                              <span className="text-red-500">*</span>
                           </label>
                           <input
                              type="password"
                              name="password"
                              value={formData.password}
                              onChange={handleChange}
                              className="w-full p-2 border rounded"
                              placeholder="Password........."
                              required
                           />
                        </div>
                        <div>
                           <label className="block mb-1 font-semibold">
                              New Password
                           </label>
                           <input
                              type="password"
                              name="newPassword"
                              value={formData.newPassword}
                              onChange={handleChange}
                              className="w-full p-2 border rounded"
                              placeholder="Password........."
                           />
                        </div>
                        <div>
                           <label className="block mb-1 font-semibold">
                              Confirm New Pass
                           </label>
                           <input
                              type="password"
                              name="confirmPassword"
                              value={conPass}
                              onChange={(e) => setConPass(e.target.value)}
                              className="w-full p-2 border rounded"
                              placeholder="Re-Confirm Password........."
                           />
                        </div>
                        <div className="flex flex-col gap-y-3 justify-center w-full mt-4">
                           <button
                              type="submit"
                              className="bg-primary hover:bg-neutral-500  w-full text-white font-bold py-2 px-4 rounded"
                           >
                              Submit
                           </button>
                           <button
                              onClick={(e) => navigate(-1)}
                              className="bg-sysRed hover:bg-neutral-500  w-full text-white font-bold py-2 px-4 rounded"
                           >
                              Cancel
                           </button>
                        </div>
                     </form>
                  </div>
               </main>
            </div>
         </div>
      </>
   );
};

UserSettings.propTypes = {
   user: PropTypes.object,
   editSelf: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
   user: state.auth.user,
});

export default connect(mapStateToProps, { editSelf })(UserSettings);
