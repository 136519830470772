import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { getSelf } from "../redux/tasks/authT";
import Spinner from "../components/Spinner";
import { toast } from "react-toastify";
import setAuthToken from "../utils/setAuthToken";

const Callback = ({ getSelf }) => {
   const navigate = useNavigate();

   useEffect(() => {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get("token");

      if (token) {
         localStorage.setItem("token", token);
         setAuthToken(token);
         getSelf(toast, navigate, token);
         //  navigate("/dashboard");
      } else {
         navigate("/");
      }
   }, [navigate, getSelf]);

   return <Spinner />;
};

Callback.propTypes = {
   getSelf: PropTypes.func.isRequired,
};
export default connect(null, { getSelf })(Callback);
