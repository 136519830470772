import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { format } from "date-fns";

import TopBar from "../../components/TopBar";
import Sidebar from "../../components/Sidebar";
import Spinner from "../../components/Spinner";
import Table from "../../components/Table";
import { loadUsersFromDep } from "../../redux/tasks/usersT";

const TableColumns = [
   {
      Header: "Name",
      accessor: (row) => `${row.firstName} ${row.lastName}`,
   },
   {
      Header: "Role",
      accessor: "role",
      Cell: ({ value }) => {
         return value === "EMPLOYEE"
            ? "Employee"
            : value === "DEPHEAD"
            ? "Department Head"
            : value === "MANAGER"
            ? "Manager"
            : "DOO";
      },
   },
   {
      Header: "Created On",
      accessor: "createdAt",
      Cell: ({ value }) => {
         return format(new Date(value), "MM-dd-yyyy");
      },
   },
];

const DepartmentEmployees = ({ loading, users, loadUsersFromDep }) => {
   const location = useLocation();
   const { dep } = location.state;
   const navigate = useNavigate();
   const [numberOptions] = useState([5, 10, 15, 20, 25, 30]);
   const [globalFilter, setGlobalFilter] = useState("");

   useEffect(() => {
      loadUsersFromDep(dep.departmentName);
   }, [loadUsersFromDep, dep]);

   const handleData = (index) => {
      const emp = users[index];
      navigate("/employee-details", { state: { emp } });
   };

   return loading ? (
      <Spinner />
   ) : (
      <>
         <div className="flex flex-col h-screen">
            <TopBar />
            <div className="flex flex-1 overflow-hidden">
               <Sidebar />
               <main className="flex-1 p-4 overflow-y-auto">
                  <div className="mx-auto">
                     <div className="flex flex-col gap-x-6 sm:flex-row sm:items-center sm:justify-end space-y-2 sm:space-y-0 sm:space-x-2 p-4">
                        {/* Add New Department Button */}
                        <button
                           onClick={(e) => navigate("/employee-register")}
                           className="bg-sysRed hover:bg-red-600 font-semibold text-white py-2 px-4 rounded sm:order-2"
                        >
                           + Add New Employee
                        </button>
                        <button
                           onClick={(e) =>
                              navigate("/department-single", { state: { dep } })
                           }
                           className="bg-sysRed hover:bg-red-600 font-semibold text-white py-2 px-4 rounded sm:order-2"
                        >
                           Department Details
                        </button>

                        {/* Search Field */}
                        <div className="flex items-center border border-gray-300 rounded sm:order-1">
                           <input
                              type="text"
                              placeholder="Search"
                              className="p-2 w-full border-none outline-none"
                              value={globalFilter}
                              onChange={(e) => setGlobalFilter(e.target.value)}
                           />
                           <div className="p-2">
                              <FaSearch className="text-gray-500" />
                           </div>
                        </div>
                     </div>
                     {users?.length > 0 ? (
                        <Table
                           data={users}
                           columns={TableColumns}
                           numberOptions={numberOptions}
                           dataFun={handleData}
                           globalFilter={globalFilter}
                           setGlobalFilter={setGlobalFilter}
                        />
                     ) : (
                        <div className="flex justify-center items-center my-20 text-2xl font-semibold">
                           This department has no employees yet.
                        </div>
                     )}
                  </div>
               </main>
            </div>
         </div>
      </>
   );
};

DepartmentEmployees.propTypes = {
   loading: PropTypes.bool.isRequired,
   users: PropTypes.array,
   loadUsersFromDep: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
   loading: state.users.loading,
   users: state.users.users,
});

export default connect(mapStateToProps, { loadUsersFromDep })(
   DepartmentEmployees
);
