import axios from "axios";

import { URL } from "../../utils/URL";
import { HOTELS, HOTELS_ERROR, HOTELS_INIT_STATE } from "./types";

export const loadHotels = () => async (dispatch) => {
   try {
      const response = await axios.get(URL + "/api/hotels/");

      dispatch({ type: HOTELS, payload: response.data });
   } catch (error) {
      if (error.response) {
         dispatch({
            type: HOTELS_ERROR,
            payload: {
               msg: error.response.data.message,
               status: error.response.status,
            },
         });
      } else {
         dispatch({
            type: HOTELS_ERROR,
            payload: {
               msg: error.message,
               status: error.status,
            },
         });
      }
   }
};

export const addHotel =
   (toast, setLoad, formData, navigate) => async (dispatch) => {
      try {
         const { name, address, phone, innCode, regionId } = formData;
         const body = JSON.stringify({
            name,
            address,
            phone,
            innCode,
            regionId,
         });
         const config = {
            headers: {
               "Content-Type": "Application/json",
            },
         };

         const res = await axios.post(URL + `/api/hotels/`, body, config);

         dispatch({
            type: HOTELS,
            payload: res.data,
         });
         setLoad(false);
         toast.success("Hotel added Successfully", {
            autoClose: 5000,
         });

         navigate("/hotels");
      } catch (e) {
         setLoad(false);
         console.log(e);
         toast.error(e.response.data.message, {
            autoClose: 5000,
         });
         dispatch({
            type: HOTELS_ERROR,
            payload: e.response.data,
         });
      }
   };

export const changeHotel =
   (toast, setLoad, id, formData, navigate) => async (dispatch) => {
      try {
         const { address, phone, innCode, regionId } = formData;
         const body = JSON.stringify({ address, phone, innCode, regionId });
         const config = {
            headers: {
               "Content-Type": "Application/json",
            },
         };

         const res = await axios.put(URL + `/api/hotels/${id}`, body, config);

         dispatch({
            type: HOTELS,
            payload: res.data,
         });
         setLoad(false);
         toast.success("Hotel Updated Successfully", {
            autoClose: 5000,
         });

         navigate("/hotels");
      } catch (e) {
         setLoad(false);
         console.log(e);
         toast.error(e.response.data.message, {
            autoClose: 5000,
         });
         dispatch({
            type: HOTELS_ERROR,
            payload: e.response.data,
         });
      }
   };

export const changeHotelAAUser =
   (toast, setLoad, formData, navigate) => async (dispatch) => {
      try {
         const { cat, userID, hotelID } = formData;
         const body = JSON.stringify({ cat, userID, hotelID });
         const config = {
            headers: {
               "Content-Type": "Application/json",
            },
         };

         const res = await axios.put(
            URL + `/api/hotels/AAUserR_M/change/`,
            body,
            config
         );

         dispatch({
            type: HOTELS,
            payload: res.data,
         });
         setLoad(false);
         toast.success("Automatic Assigment Updated Successfully", {
            autoClose: 5000,
         });

         // navigate("/");
      } catch (e) {
         setLoad(false);
         console.log(e);
         toast.error(e.response.data.message, {
            autoClose: 5000,
         });
         dispatch({
            type: HOTELS_ERROR,
            payload: e.response.data,
         });
      }
   };

export const deleteHotel =
   (toast, setLoad, id, navigate) => async (dispatch) => {
      try {
         const confirm = window.confirm(
            "Are you sure you want to remove this hotel permanently?"
         );
         if (confirm) {
            await axios.delete(URL + `/api/hotels/${id}`);
            setLoad(false);
            toast.success("Hotel Deleted Successfully", {
               autoClose: 5000,
            });
            dispatch({ type: HOTELS_INIT_STATE });
            navigate("/hotels");
         }
      } catch (error) {
         setLoad(false);
         toast.error(error.response.data.message, {
            autoClose: 5000,
         });
         dispatch({
            type: HOTELS_ERROR,
            payload: {
               msg: error.response.data.message,
               status: error.response.status,
            },
         });
      }
   };
