import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { loadTicketsDep } from "../../redux/tasks/ticketsT";
import TopBar from "../../components/TopBar";
import Sidebar from "../../components/Sidebar";
import Spinner from "../../components/Spinner";
import ParamGraph from "../../components/ParamGraph";

const DashboardDepHead = ({ loading, tickets, role, loadTicketsDep }) => {
   useEffect(() => {
      loadTicketsDep();
   }, [loadTicketsDep]);
   return loading ? (
      <Spinner />
   ) : (
      <>
         <div className="flex flex-col h-screen">
            <TopBar />
            <div className="flex flex-1 overflow-hidden">
               <Sidebar />
               <main className="flex-1 p-4 overflow-y-auto">
                  {tickets?.length > 0 ? (
                     <ParamGraph tickets={tickets} role={role} />
                  ) : (
                     <h1>No Tickets available</h1>
                  )}
               </main>
            </div>
         </div>
      </>
   );
};

DashboardDepHead.proopTypes = {
   loading: PropTypes.bool.isRequired,
   tickets: PropTypes.array,
   role: PropTypes.string,
   loadTicketsDep: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
   loading: state.tickets.loading,
   tickets: state.tickets.tickets,
   role: state.auth.user.role,
});

export default connect(mapStateToProps, { loadTicketsDep })(DashboardDepHead);
