import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FiMenu } from "react-icons/fi";
import { FaTimes } from "react-icons/fa";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const Sidebar = ({ role, department }) => {
   const [sidebarOpen, setSidebarOpen] = useState(false);
   const location = useLocation();

   const toggleSidebar = () => {
      setSidebarOpen(!sidebarOpen);
   };

   const menuItems =
      role === "ADMIN"
         ? [
              { name: "Dashboard", path: "/admin-dashboard" },
              { name: "Departments", path: "/departments" },
              { name: "Tickets", path: "/tickets-all" },
              { name: "Hotels", path: "/hotels" },
           ]
         : (role === "DEPHEAD" || role === "DOO" || role === "MANAGER") &&
           department === "Repair & Maintenance"
         ? [
              { name: "Dashboard", path: "/dephead-dashboard" },
              { name: "Escalated Tickets", path: "/escalated-tickets" },
              { name: "Unassigned Tickets", path: "/unassigned-tickets" },
              { name: "Open Tickets", path: "/open-tickets-dep" },
              { name: "Closed Tickets", path: "/closed-tickets-dep" },
              { name: "Settings", path: "/aa-users" },
           ]
         : role === "DEPHEAD" && department !== "Repair & Maintenance"
         ? [
              { name: "Dashboard", path: "/dephead-dashboard" },
              { name: "Unassigned Tickets", path: "/unassigned-tickets" },
              { name: "Open Tickets", path: "/open-tickets-dep" },
              { name: "Closed Tickets", path: "/closed-tickets-dep" },
              { name: "Settings", path: "/aa-users" },
           ]
         : role === "EMPLOYEE"
         ? [
              // { name: "Dashboard", path: "/employee-dashboard" },
              { name: "Open Tickets", path: "/open-tickets-emp" },
              { name: "Closed Tickets", path: "/closed-tickets-emp" },
           ]
         : [
              { name: "Dashboard", path: "/user-dashboard" },
              { name: "New Ticket", path: "/new-ticket" },
              { name: "Open Tickets", path: "/open-tickets" },
              { name: "Closed Tickets", path: "/closed-tickets" },
           ];

   return (
      <div>
         <div className="lg:hidden flex justify-between items-center p-4 bg-white shadow-md">
            <FiMenu
               className="text-2xl cursor-pointer"
               onClick={toggleSidebar}
            />
         </div>
         <div
            className={`fixed inset-0 lg:relative lg:flex flex-col bg-primary text-white w-64 lg:w-64 h-full z-50 transform ${
               sidebarOpen ? "translate-x-0" : "-translate-x-full"
            } lg:translate-x-0 transition-transform duration-300 ease-in-out mt-16 lg:mt-0`}
         >
            <div className="flex justify-between items-center p-4 lg:hidden">
               <FaTimes
                  className="text-2xl cursor-pointer"
                  onClick={toggleSidebar}
               />
            </div>
            <div className="p-4">
               <nav className="space-y-2">
                  {menuItems.map((item) => (
                     <Link
                        key={item.name}
                        to={item.path}
                        className={`block px-4 py-2 rounded-md ${
                           location.pathname === item.path
                              ? "bg-secondry font-bold"
                              : "hover:bg-secondry"
                        }`}
                        onClick={() => setSidebarOpen(false)}
                     >
                        {item.name}
                     </Link>
                  ))}
               </nav>
            </div>
         </div>
         {sidebarOpen && (
            <div
               className="fixed inset-0 bg-black opacity-50 lg:hidden"
               onClick={toggleSidebar}
            ></div>
         )}
      </div>
   );
};

Sidebar.propTypes = {
   role: PropTypes.string,
   department: PropTypes.string,
};

const mapStateToProps = (state) => ({
   role: state.auth.user.role,
   department: state.auth.user.department,
});

export default connect(mapStateToProps)(Sidebar);
