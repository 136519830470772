import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FaFileAlt, FaDownload } from "react-icons/fa";
import { format } from "date-fns";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { loadUsersFromMyDep } from "../redux/tasks/usersT";
import { loadMyDep } from "../redux/tasks/departmentsT";
import {
   addComment,
   addAssignment,
   addStatus,
   addEscalation,
} from "../redux/tasks/ticketsT";
import TopBar from "../components/TopBar";
import Sidebar from "../components/Sidebar";
import { toast } from "react-toastify";
import Spinner from "../components/Spinner";

const SingleTicket = ({
   user,
   addComment,
   usersLoading,
   users,
   loadUsersFromMyDep,
   depLoading,
   department,
   loadMyDep,
   addAssignment,
   addStatus,
   addEscalation,
}) => {
   const location = useLocation();
   const { ticket } = location.state;
   const navigate = useNavigate();
   const [load, setLoad] = useState(false);
   const [data, setData] = useState({
      visibility: "ALL",
      attachment: null,
   });
   const [updateData, setUpdateData] = useState({
      category: "",
      priority: "",
      assignTo: "",
      deadline: "",
   });

   useEffect(() => {
      if (
         user.role === "DEPHEAD" ||
         user.role === "DOO" ||
         user.role === "MANAGER"
      ) {
         loadMyDep();
         loadUsersFromMyDep();
         if (ticket) {
            setUpdateData({
               category: ticket.category,
               priority: ticket.priority,
               assignTo: ticket.assignedTo?._id,
               deadline: ticket.deadline
                  ? format(new Date(ticket.deadline), "yyyy-MM-dd")
                  : "",
            });
         }
      }
   }, [loadUsersFromMyDep, loadMyDep, ticket, user]);

   const handleChange = (e) => {
      const { name, value, files } = e.target;
      setData({
         ...data,
         [name]: files ? Array.from(files) : value,
      });
   };

   const handleUpdateChange = (e) => {
      const { name, value } = e.target;
      setUpdateData({
         ...updateData,
         [name]: value,
      });
   };

   const handleSubmit = (e) => {
      e.preventDefault();

      const formData = new FormData();
      if (data.attachment && data.attachment.length > 0) {
         data.attachment.forEach((file, index) => {
            formData.append(`attachment`, file);
         });
      }

      // Append other form data fields if any
      Object.keys(data).forEach((key) => {
         if (key !== "attachment") {
            formData.append(key, data[key]);
         }
      });
      setLoad(true);
      addComment(ticket._id, toast, setLoad, formData, navigate, user.role);
   };

   const handleUpdateSubmit = (e) => {
      e.preventDefault();
      setLoad(true);
      addAssignment(ticket._id, toast, setLoad, updateData, navigate);
   };

   const handleEscalation = (e) => {
      e.preventDefault();
      setLoad(true);
      addEscalation(ticket._id, toast, setLoad, navigate);
   };

   let detailedFields = {};
   if (ticket.detailedFields) {
      try {
         detailedFields = JSON.parse(ticket.detailedFields);
      } catch (e) {
         console.error("Failed to parse detailedFields", e);
      }
   }

   const renderValue = (value) => {
      if (typeof value === "object" && value !== null) {
         return (
            <div className="pl-4">
               {Object.keys(value).map((subKey) => (
                  <div key={subKey}>
                     <span className="font-semibold capitalize">
                        {subKey.replace(/_/g, " ")}:
                     </span>
                     <span className="ml-2">{renderValue(value[subKey])}</span>
                  </div>
               ))}
            </div>
         );
      }
      if (typeof value === "boolean") {
         return value ? "Yes" : "No";
      }
      return value;
   };

   return load ? (
      <Spinner />
   ) : (
      <>
         <div className="flex flex-col h-screen">
            <TopBar />
            <div className="flex flex-1 overflow-hidden">
               <Sidebar />
               <main className="flex-1 p-4 overflow-y-auto relative">
                  {/* Container for the button */}
                  {ticket.status === "RESOLVED" ? (
                     <></>
                  ) : (
                     <>
                        {user.role === "ADMIN" ? (
                           <></>
                        ) : user.role === "DEPHEAD" ||
                          user.role === "DOO" ||
                          user.role === "MANAGER" ? (
                           <>
                              <div className="absolute flex gap-x-3 top-4 right-4">
                                 <button
                                    className={`font-bold py-2 px-4 rounded ${
                                       ticket.status === "MARKEDASRESOLVED"
                                          ? "bg-gray-300 text-gray-700 cursor-not-allowed"
                                          : "bg-green-600 hover:bg-neutral-600 text-white "
                                    }`}
                                    onClick={(e) => {
                                       setLoad(true);
                                       addStatus(
                                          ticket._id,
                                          toast,
                                          setLoad,
                                          "RESOLVED",
                                          navigate
                                       );
                                    }}
                                 >
                                    Close Ticket
                                 </button>
                                 <button
                                    className={`font-bold py-2 px-4 rounded bg-sysRed hover:bg-neutral-600 text-white `}
                                    onClick={handleUpdateSubmit}
                                 >
                                    Update Ticket
                                 </button>
                              </div>
                           </>
                        ) : // : user.role === "ENDUSER" ? (
                        //    <div className="absolute top-4 right-4">
                        //       <button
                        //          className={`font-bold py-2 px-4 rounded ${
                        //             ticket.status === "MARKEDASRESOLVED"
                        //                ? "bg-primary hover:bg-neutral-700 text-white"
                        //                : "bg-gray-300 text-gray-700 cursor-not-allowed"
                        //          }`}
                        //          disabled={ticket.status !== "MARKEDASRESOLVED"}
                        //          onClick={(e) =>
                        //             addStatus(
                        //                ticket._id,
                        //                toast,
                        //                setLoad,
                        //                "RESOLVED",
                        //                navigate
                        //             )
                        //          }
                        //       >
                        //          Close Ticket
                        //       </button>
                        //    </div>
                        // )
                        user.role === "EMPLOYEE" ? (
                           <div className="absolute flex gap-x-3 top-4 right-4">
                              <button
                                 className={`font-bold py-2 px-4 rounded ${
                                    ticket.status === "MARKEDASRESOLVED"
                                       ? "bg-gray-300 text-gray-700 cursor-not-allowed"
                                       : "bg-green-600 hover:bg-neutral-600 text-white cursor-pointer"
                                 }`}
                                 onClick={(e) => {
                                    setLoad(true);
                                    addStatus(
                                       ticket._id,
                                       toast,
                                       setLoad,
                                       "RESOLVED",
                                       navigate
                                    );
                                 }}
                              >
                                 Close Ticket
                              </button>
                           </div>
                        ) : (
                           <></>
                        )}

                        {user.department === "Repair & Maintenance" &&
                        user.role === "EMPLOYEE" ? (
                           <>
                              <div className="absolute flex gap-x-3 top-16 right-4">
                                 <button
                                    disabled={
                                       ticket.status === "ESCALATETOMANAGER" ||
                                       ticket.status === "ESCALATETODOO" ||
                                       ticket.status === "ESCALATETODEPHEAD"
                                    }
                                    className={`font-bold py-2 px-4 rounded ${
                                       ticket.status === "ESCALATETOMANAGER" ||
                                       ticket.status === "ESCALATETODOO" ||
                                       ticket.status === "ESCALATETODEPHEAD"
                                          ? "bg-gray-300 text-gray-700 cursor-not-allowed"
                                          : "bg-sysRed hover:bg-neutral-600 text-white "
                                    }`}
                                    onClick={(e) => handleEscalation(e)}
                                 >
                                    Escalate Ticket
                                 </button>
                              </div>
                           </>
                        ) : user.department === "Repair & Maintenance" &&
                          user.role === "MANAGER" ? (
                           <>
                              <div className="absolute flex gap-x-3 top-16 right-4">
                                 <button
                                    disabled={
                                       ticket.status === "ESCALATETODOO" ||
                                       ticket.status === "ESCALATETODEPHEAD"
                                    }
                                    className={`font-bold py-2 px-4 rounded ${
                                       ticket.status === "ESCALATETODOO" ||
                                       ticket.status === "ESCALATETODEPHEAD"
                                          ? "bg-gray-300 text-gray-700 cursor-not-allowed"
                                          : "bg-sysRed hover:bg-neutral-600 text-white "
                                    }`}
                                    onClick={(e) => handleEscalation(e)}
                                 >
                                    Escalate Ticket
                                 </button>
                              </div>
                           </>
                        ) : user.department === "Repair & Maintenance" &&
                          user.role === "DOO" ? (
                           <>
                              <div className="absolute flex gap-x-3 top-16 right-4">
                                 <button
                                    disabled={
                                       ticket.status === "ESCALATETODEPHEAD"
                                    }
                                    className={`font-bold py-2 px-4 rounded ${
                                       ticket.status === "ESCALATETODEPHEAD"
                                          ? "bg-gray-300 text-gray-700 cursor-not-allowed"
                                          : "bg-sysRed hover:bg-neutral-600 text-white "
                                    }`}
                                    onClick={(e) => handleEscalation(e)}
                                 >
                                    Escalate Ticket
                                 </button>
                              </div>
                           </>
                        ) : (
                           <></>
                        )}
                     </>
                  )}

                  <div className="p-4 max-w-4xl mx-auto">
                     <div className="mb-4">
                        <div className="flex gap-x-5 items-center mb-2">
                           <span className="font-semibold">
                              Department Name :
                           </span>
                           <span>{ticket.department}</span>
                        </div>
                        <div className="flex gap-x-5 items-center mb-2">
                           <span className="font-semibold">Hotel :</span>
                           <span>{ticket.hotel}</span>
                        </div>
                        <div className="flex gap-x-5 items-center mb-2">
                           <span className="font-semibold">Category :</span>
                           {user.role === "DEPHEAD" ||
                           user.role === "DOO" ||
                           user.role === "MANAGER" ? (
                              <select
                                 name="category"
                                 value={updateData.category}
                                 // onChange={handleUpdateChange}
                                 className="px-2 py-1 border rounded text-center"
                                 required
                              >
                                 <option value="">Select category</option>
                                 {department?.categories &&
                                    department.categories.map((cat, index) => (
                                       <option
                                          key={index}
                                          value={cat.categoryName}
                                       >
                                          {cat.categoryName}
                                       </option>
                                    ))}
                              </select>
                           ) : (
                              <span>{ticket.category}</span>
                           )}
                        </div>
                        <div className="flex gap-x-5 items-center mb-2">
                           <span className="font-semibold">Priority :</span>
                           {user.role === "DEPHEAD" ||
                           user.role === "DOO" ||
                           user.role === "MANAGER" ? (
                              <select
                                 name="priority"
                                 value={updateData.priority}
                                 onChange={handleUpdateChange}
                                 className="px-2 py-1 border rounded text-center"
                                 required
                              >
                                 <option value="">Select priority</option>
                                 <option value="LOW">Low</option>
                                 <option value="MEDIUM">Medium</option>
                                 <option value="HIGH">High</option>
                              </select>
                           ) : (
                              <span>{ticket.priority}</span>
                           )}
                        </div>
                        <div className="flex gap-x-5 items-center mb-2">
                           <span className="font-semibold">
                              Ticket Subject :
                           </span>
                           <span>{ticket.subject}</span>
                        </div>
                        <div className="flex gap-x-5 items-center mb-2">
                           <span className="font-semibold">Status :</span>
                           <span>
                              {ticket.status === "WORKINPROGRESS"
                                 ? "Work in Progress"
                                 : ticket.status === "MARKEDASRESOLVED"
                                 ? "Marked as  Resolved"
                                 : ticket.status === "RESOLVED"
                                 ? "Closed Ticket"
                                 : ticket.status === "ESCALATETOMANAGER"
                                 ? "Escalated to manager"
                                 : ticket.status === "ESCALATETODOO"
                                 ? "Escaalated to DOO"
                                 : ticket.status === "ESCALATETODEPHEAD"
                                 ? "Escaalated to department head"
                                 : "Un Assigned"}
                           </span>
                        </div>
                        <div className="flex gap-x-5 items-center mb-2">
                           <span className="font-semibold">
                              Currently Assigned To :
                           </span>
                           {user.role === "DEPHEAD" ||
                           user.role === "DOO" ||
                           user.role === "MANAGER" ? (
                              <select
                                 name="assignTo"
                                 value={updateData.assignTo}
                                 onChange={handleUpdateChange}
                                 className="px-2 py-1 border rounded text-center"
                                 required
                              >
                                 <option value="">Select User</option>
                                 {users?.length > 0 &&
                                    users?.map((u) => (
                                       <option key={u._id} value={u._id}>
                                          {u.firstName + " " + u.lastName}
                                       </option>
                                    ))}
                              </select>
                           ) : (
                              <span>
                                 {ticket.assignedTo
                                    ? ticket.assignedTo.firstName
                                    : "No one yet!"}
                              </span>
                           )}
                        </div>
                        <div className="flex gap-x-5 items-center mb-2">
                           <span className="font-semibold">Deadline :</span>
                           {user.role === "DEPHEAD" ||
                           user.role === "DOO" ||
                           user.role === "MANAGER" ? (
                              <input
                                 name="deadline"
                                 type="date"
                                 value={updateData.deadline}
                                 onChange={handleUpdateChange}
                                 className=" px-2 py-1 border rounded text-center"
                              />
                           ) : (
                              <span>
                                 {ticket.deadline
                                    ? format(
                                         new Date(ticket.deadline),
                                         "dd-MM-yyyy"
                                      )
                                    : "No deadline Assigned yet"}
                              </span>
                           )}
                        </div>
                        <div className="flex gap-x-5 items-center mb-2">
                           <span className="font-semibold">Issues :</span>
                           <span className="font-normal">{ticket.issues}</span>
                        </div>
                     </div>
                     <span className="font-semibold">Ticket Details :</span>
                     <div className="flex gap-x-5 items-center my-2">
                        <span className="font-semibold">
                           Ticket generated by :
                        </span>
                        <span className="font-normal">
                           {ticket.opener.firstName +
                              " " +
                              ticket.opener.lastName}
                        </span>
                     </div>
                     <div className="flex gap-x-5 items-center mb-2">
                        <span className="font-semibold">
                           Ticket generator's email :
                        </span>
                        <span className="font-normal">
                           {ticket.opener.email}
                        </span>
                     </div>
                     {user.role === "EMPLOYEE" ||
                     user.role === "DEPHEAD" ||
                     user.role === "DOO" ||
                     user.role === "MANAGER" ? (
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                           {Object.keys(detailedFields).map((key) => (
                              <div
                                 key={key}
                                 className="p-4 border-b md:border-r md:border-b-0"
                              >
                                 <span className="block capitalize">
                                    {key.replace(/_/g, " ")}:
                                    {typeof detailedFields[key] === "object" ? (
                                       <span className="block mt-1 font-light">
                                          {renderValue(detailedFields[key])}{" "}
                                       </span>
                                    ) : detailedFields[key] === true ? (
                                       "Yes"
                                    ) : detailedFields[key] === false ? (
                                       "No"
                                    ) : (
                                       "   " + detailedFields[key]
                                    )}
                                 </span>
                              </div>
                           ))}
                        </div>
                     ) : (
                        <></>
                     )}
                     <div className="mb-4">
                        <h2 className="text-xl font-bold mb-4">Comments</h2>
                        {ticket.comments.map((comment, index) =>
                           user.role === "ENDUSER" &&
                           comment.commentVisibility === "DEPARTMENT" ? (
                              <></>
                           ) : (
                              <div
                                 key={index}
                                 className="bg-stone-300 rounded p-4 mb-4"
                              >
                                 <p>
                                    <span className="font-semibold">
                                       Comment:
                                    </span>{" "}
                                    {comment.commentText}
                                 </p>
                                 <div className="mt-4 flex gap-x-5">
                                    {comment.attachments &&
                                       comment.attachments.map(
                                          renderAttachment
                                       )}
                                 </div>
                                 <div className="flex justify-between items-center mt-4">
                                    <div className="flex items-center">
                                       <span className="font-semibold">
                                          {comment.commentor.firstName}
                                       </span>
                                       <span className="ml-2">|</span>
                                       <span className="ml-2 text-neutral-500">
                                          {comment.commentor.role}
                                       </span>
                                    </div>
                                    <span className="text-gray-500">
                                       {format(
                                          new Date(comment.createdAt),
                                          "dd-MM-yyyy"
                                       )}
                                    </span>
                                 </div>
                              </div>
                           )
                        )}
                     </div>
                     <div className="mb-4">
                        {ticket.status === "RESOLVED" ? (
                           <></>
                        ) : (
                           <>
                              {user.role === "ENDUSER" ? (
                                 <></>
                              ) : (
                                 <div className="flex flex-row w-full justify-between">
                                    <h2 className="text-xl font-bold mb-4">
                                       Add Comment
                                    </h2>
                                    <div className="flex flex-row w-1/2 justify-end items-center gap-x-2">
                                       <label className="block mb-1 font-semibold">
                                          Comment Visibility:
                                       </label>
                                       <select
                                          name="visibility"
                                          value={data.visibility}
                                          onChange={handleChange}
                                          className="w-1/2 p-2 border rounded text-center"
                                          required
                                       >
                                          <option value="ALL">All</option>
                                          <option value="DEPARTMENT">
                                             Inter-department
                                          </option>
                                       </select>
                                    </div>
                                 </div>
                              )}
                              <textarea
                                 className="w-full p-2 border rounded mb-4"
                                 placeholder="Write Your Comment Here..."
                                 name="commentText"
                                 required
                                 value={data.commentText}
                                 onChange={handleChange}
                              ></textarea>
                              <div className="flex justify-between items-center mb-4">
                                 <input
                                    type="file"
                                    name="attachment"
                                    multiple
                                    onChange={handleChange}
                                    className="w-full p-2 border rounded"
                                 />
                              </div>
                              <button
                                 onClick={(e) => navigate(-1)}
                                 className="bg-neutral-400 hover:bg-neutral-600 mr-5 text-white font-bold py-2 px-8 rounded"
                              >
                                 Back
                              </button>
                              <button
                                 onClick={(e) => handleSubmit(e)}
                                 className="bg-red-500 hover:bg-neutral-600 text-white font-bold py-2 px-4 rounded"
                              >
                                 Post Comment
                              </button>
                           </>
                        )}
                     </div>
                  </div>
               </main>
            </div>
         </div>
      </>
   );
};

const renderAttachment = (attachment) => {
   const isImage = /\.(jpg|jpeg|png|gif|bmp|webp)$/i.test(attachment);

   return (
      <div key={attachment} className="relative w-16 h-16 mb-2 group">
         {isImage ? (
            <img
               src={attachment}
               alt="attachment"
               className="w-full h-full object-cover"
            />
         ) : (
            <div className="w-full h-full flex items-center justify-center bg-gray-200">
               <FaFileAlt className="text-2xl" />
               <span className="ml-2">File</span>
            </div>
         )}
         <a
            href={attachment}
            download
            className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white opacity-0 group-hover:opacity-100 transition-opacity duration-300"
         >
            <FaDownload className="text-xl" />
         </a>
      </div>
   );
};

SingleTicket.propTypes = {
   user: PropTypes.object,
   addComment: PropTypes.func.isRequired,
   usersLoading: PropTypes.bool.isRequired,
   users: PropTypes.array,
   loadUsersFromMyDep: PropTypes.func.isRequired,
   depLoading: PropTypes.bool.isRequired,
   department: PropTypes.object,
   loadMyDep: PropTypes.func.isRequired,
   addAssignment: PropTypes.func.isRequired,
   addStatus: PropTypes.func.isRequired,
   addEscalation: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
   user: state.auth.user,
   usersLoading: state.users.loading,
   users: state.users.users,
   depLoading: state.departments.singleDepartmentLoading,
   department: state.departments.singleDepartment,
});

export default connect(mapStateToProps, {
   addComment,
   loadUsersFromMyDep,
   loadMyDep,
   addAssignment,
   addStatus,
   addEscalation,
})(SingleTicket);
