export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const SELF_CHANGE = "SELF_CHANGE";
export const SELF_ERROR = "SELF_ERROR";
export const GET_SELF = "GET_SELF";
export const LOGOUT = "LOGOUT";

export const HOTELS = "HOTELS";
export const HOTELS_INIT_STATE = "HOTELS_INIT_STATE";
export const HOTELS_ERROR = "HOTELS_ERROR";

export const REGIONS = "REGIONS";
export const REGIONS_INIT_STATE = "REGIONS_INIT_STATE";
export const REGIONS_ERROR = "REGIONS_ERROR";

export const DEPARTMENTS = "DEPARTMENTS";
export const DEPARTMENTS_SINGLE = "DEPARTMENTS_SINGLE";
export const DEPARTMENTS_ERROR = "DEPARTMENTS_ERROR";

export const TICKETS = "TICKETS";
export const TICKETS_INIT_STATE = "TICKETS_INIT_STATE";
export const TICKETS_OPEN = "TICKETS_OPEN";
export const TICKETS_CLOSED = "TICKETS_CLOSED";
export const TICKETS_ESCALATED = "TICKETS_ESCALATED";
export const TICKETS_ERROR = "TICKETS_ERROR";

export const USERS = "USERS";
export const USERS_INIT_STATE = "USERS_INIT_STATE";
export const USERS_ERROR = "USERS_ERROR";
