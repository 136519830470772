import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { addUser } from "../../redux/tasks/usersT";
import TopBar from "../../components/TopBar";
import Sidebar from "../../components/Sidebar";
import Spinner from "../../components/Spinner";

const EmployeeRegister = ({ departments, addUser }) => {
   const navigate = useNavigate();
   const [load, setLoad] = useState(false);
   const [formData, setFormData] = useState({
      firstName: "",
      lastName: "",
      email: "",
      department: "",
      role: "",
      phone: "",
      //   password: "",
      //   confirmPassword: "",
   });

   const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
   };

   const handleSubmit = (e) => {
      e.preventDefault();
      setLoad(true);
      addUser({ toast, setLoad, formData, navigate });
   };

   return load ? (
      <Spinner />
   ) : (
      <div className="flex flex-col h-screen">
         <TopBar />
         <div className="flex flex-1 overflow-hidden">
            <Sidebar />
            <main className="flex-1 p-4 overflow-y-auto">
               <div className="flex flex-col items-center justify-center h-full">
                  <h1 className="text-3xl font-bold mb-6 text-center">
                     Register New Employee
                  </h1>
                  <form
                     onSubmit={handleSubmit}
                     className="w-full max-w-2xl space-y-4"
                  >
                     <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                        <div>
                           <label className="block mb-1 font-semibold">
                              First Name<span className="text-red-500">*</span>
                           </label>
                           <input
                              type="text"
                              name="firstName"
                              value={formData.firstName}
                              onChange={handleChange}
                              className="w-full p-2 border rounded"
                              placeholder="Enter Your Title...."
                              required
                           />
                        </div>
                        <div>
                           <label className="block mb-1 font-semibold">
                              Last Name<span className="text-red-500">*</span>
                           </label>
                           <input
                              type="text"
                              name="lastName"
                              value={formData.lastName}
                              onChange={handleChange}
                              className="w-full p-2 border rounded"
                              placeholder="Enter your Last Name here....."
                              required
                           />
                        </div>
                        <div>
                           <label className="block mb-1 font-semibold">
                              Email<span className="text-red-500">*</span>
                           </label>
                           <input
                              type="email"
                              name="email"
                              value={formData.email}
                              onChange={handleChange}
                              className="w-full p-2 border rounded"
                              placeholder="Enter Your Email...."
                              required
                           />
                        </div>
                        <div>
                           <label className="block mb-1 font-semibold">
                              Department<span className="text-red-500">*</span>
                           </label>
                           <select
                              name="department"
                              value={formData.department}
                              onChange={handleChange}
                              className="w-full p-2 border rounded"
                              required
                           >
                              <option value="">Select Here</option>
                              {departments?.map((d) => (
                                 <option key={d._id} value={d.departmentName}>
                                    {d.departmentName}
                                 </option>
                              ))}
                           </select>
                        </div>
                        <div>
                           <label className="block mb-1 font-semibold">
                              Role<span className="text-red-500">*</span>
                           </label>
                           <select
                              name="role"
                              value={formData.role}
                              onChange={handleChange}
                              className="w-full p-2 border rounded"
                              required
                           >
                              <option value="">Select Here</option>
                              <option value="EMPLOYEE">Employee</option>
                              <option value="MANAGER">Manager</option>
                              <option value="DOO">
                                 Director of Operations (DOO)
                              </option>
                              <option value="DEPHEAD">Department Head</option>
                           </select>
                        </div>
                        <div>
                           <label className="block mb-1 font-semibold">
                              Contact No:<span className="text-red-500">*</span>
                           </label>
                           <input
                              type="text"
                              name="phone"
                              value={formData.phone}
                              onChange={handleChange}
                              className="w-full p-2 border rounded"
                              placeholder="Enter your contact number"
                              required
                           />
                        </div>
                     </div>
                     {/* <div>
                        <label className="block mb-1 font-semibold">
                           Password<span className="text-red-500">*</span>
                        </label>
                        <input
                           type="password"
                           name="password"
                           value={formData.password}
                           onChange={handleChange}
                           className="w-full p-2 border rounded"
                           placeholder="Password........."
                           required
                        />
                     </div> */}
                     {/* <div>
                        <label className="block mb-1 font-semibold">
                           Confirm Pass<span className="text-red-500">*</span>
                        </label>
                        <input
                           type="password"
                           name="confirmPassword"
                           value={formData.confirmPassword}
                           onChange={handleChange}
                           className="w-full p-2 border rounded"
                           placeholder="Re- Confirm Password........."
                           required
                        />
                     </div> */}
                     <div className="flex flex-col gap-y-3 justify-center w-full mt-4">
                        <button
                           type="submit"
                           className="bg-primary hover:bg-neutral-500  w-full text-white font-bold py-2 px-4 rounded"
                        >
                           Submit
                        </button>
                        <button
                           onClick={(e) => navigate(-1)}
                           className="bg-sysRed hover:bg-neutral-500  w-full text-white font-bold py-2 px-4 rounded"
                        >
                           Cancel
                        </button>
                     </div>
                  </form>
               </div>
            </main>
         </div>
      </div>
   );
};

EmployeeRegister.propTypes = {
   departments: PropTypes.array.isRequired,
   addUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
   departments: state.departments.departments,
});

export default connect(mapStateToProps, { addUser })(EmployeeRegister);
